import validate from '@/manual-review/validations';
import { FieldType } from '@/manual-review/form';
import { Document, CountryAlpha3 } from './types';

const foreignIDRegexp = /^[0-9]{5,9}$/;
const expectedMRZLength = 90;
const td1MRZRegexp = /^[A-Z0-9<]+$/;

export function validateDocumentNumber(value: string): string | boolean {
  if (!foreignIDRegexp.test(value)) {
    return 'Digite 5 a 9 dígitos';
  }

  return true;
}

export function validateMRZ(value: string): string | boolean {
  if (value.length !== expectedMRZLength) {
    return `Digite un MRZ de ${expectedMRZLength} caracteres`;
  }

  if (!td1MRZRegexp.test(value)) {
    return 'Digite un MRZ con caracteres válidos';
  }

  return true;
}

const doc: Document = {
  sides: {
    front: {
      fields: [
        {
          type: FieldType.Select,
          name: 'document_version',
          label: 'Versión de documento',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'Cédula de extranjería', value: 'co_foreign-id' },
          ],
          alwaysRequired: true,
        },
        {
          type: FieldType.Text,
          name: 'name',
          label: 'Nombres',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'last_name',
          label: 'Apellidos',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'document_number',
          label: 'Número de documento',
          rules: [validate.isRequired, validateDocumentNumber],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'nationality',
          label: 'Nacionalidad',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'date_of_birth',
          label: 'Fecha de nacimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
          allowedDates: validate.dateOfBirth,
          value: '',
        },
        {
          type: FieldType.Select,
          name: 'gender',
          label: 'Sexo',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'M', value: 'male' },
            { label: 'F', value: 'female' },
          ],
        },
        {
          type: FieldType.Date,
          name: 'expedition_date',
          label: 'Fecha de expedición',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpedition],
          allowedDates: validate.dateOfExpedition,
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'expiration_date',
          label: 'Fecha de vencimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpiration],
          allowedDates: validate.dateOfExpiration,
          value: '',
        },
        {
          type: FieldType.Select,
          name: 'rh',
          label: 'RH',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'A+', value: 'A+' },
            { label: 'B+', value: 'B+' },
            { label: 'AB+', value: 'AB+' },
            { label: 'O+', value: 'O+' },
            { label: 'A-', value: 'A-' },
            { label: 'B-', value: 'B-' },
            { label: 'AB-', value: 'AB-' },
            { label: 'O-', value: 'O-' },
          ],
        },

      ],
    },
    reverse: {
      fields: [
        {
          type: FieldType.Td1MRZ,
          name: 'machine_readable',
          label: 'Machine-Readable Zone (TD1)',
          rules: [validate.isRequired, validateMRZ],
          value: '',
          td1MRZFields: {
            issuingCountry: {
              type: FieldType.Select,
              name: 'mrz_issuing_country',
              label: 'MRZ issuing country',
              rules: [validate.isRequired],
              value: '',
              options: CountryAlpha3,
            },
            documentNumber: {
              type: FieldType.Text,
              name: 'mrz_document_number',
              label: 'MRZ document number',
              rules: [validate.isRequired, validateDocumentNumber],
              value: '',
            },
            dateOfBirth: {
              type: FieldType.Date,
              name: 'mrz_date_of_birth',
              label: 'MRZ date of birth',
              rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
              allowedDates: validate.dateOfBirth,
              value: '',
            },
            gender: {
              type: FieldType.Select,
              name: 'mrz_gender',
              label: 'MRZ gender',
              rules: [validate.isRequired],
              value: '',
              options: [
                { label: 'M', value: 'M' },
                { label: 'F', value: 'F' },
              ],
            },
            expirationDate: {
              type: FieldType.Date,
              name: 'mrz_expiration_date',
              label: 'MRZ expiration date',
              rules: [validate.isRequired, validate.isDate, validate.dateOfExpiration],
              allowedDates: validate.dateOfExpiration,
              value: '',
            },
            nationality: {
              type: FieldType.Select,
              name: 'mrz_nationality',
              label: 'MRZ nationality',
              rules: [validate.isRequired],
              value: '',
              options: CountryAlpha3,
            },
            lastName: {
              type: FieldType.Text,
              name: 'mrz_last_name',
              label: 'MRZ last name',
              rules: [validate.isRequired, validate.name],
              value: '',
            },
            name: {
              type: FieldType.Text,
              name: 'mrz_name',
              label: 'MRZ name',
              rules: [validate.isRequired, validate.name],
              value: '',
            },
          },
        },
      ],
    },
  },
};

export default doc;
