
import Vue from 'vue';
import { Field, FieldType } from '@/manual-review/form';
import { isType } from '@/documents-validation/shared_funtions';
import Year from '@/components/Year.vue';

export default Vue.extend({
  data() {
    return {
      pickerActive: false,
    };
  },

  components: {
    Year,
  },

  props: {
    field: {
      type: Object as () => Field,
    },
  },

  methods: {
    closePicker() {
      this.pickerActive = false;
    },
  },

  computed: {
    isText(): boolean {
      return isType(this.field, FieldType.Text);
    },
    isSelect(): boolean {
      return isType(this.field, FieldType.Select);
    },
    isMultipleOptions(): boolean {
      return isType(this.field, FieldType.MultipleOptions);
    },
    isDate(): boolean {
      return isType(this.field, FieldType.Date);
    },
    isYear(): boolean {
      return isType(this.field, FieldType.Year);
    },
  },
});
