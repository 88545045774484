
import Vue, { PropType } from 'vue';
import FormInput from '@/components/FormInput.vue';
import Actions from '@/components/Actions.vue';
import { getTasks } from '@/manual-review/tasks';
import { ValidationType } from '@/manual-review/validations';
import { getTagsField } from '@/manual-review/tag';
import { getTurkAssignmentID, isTaskAssigned, submitText } from '@/manual-review/assignment';
import { Field, Option } from '@/manual-review/form';
import ValidationDetails from '@/components/ValidationDetails.vue';
import { FaceValidationProcessor, Action } from '@/face-validation/types';
import tags from '@/face-validation/tags';

type FaceValidation = {
  tasks: Array<Field>;
  tagsField: Field;
  assignmentId: string;
};

interface Form {
  validate?(): boolean;
}

export default Vue.extend({
  data() {
    const data: FaceValidation = {
      tasks: [],
      tagsField: getTagsField(tags),
      assignmentId: '',
    };

    return data;
  },

  components: {
    FormInput,
    ValidationDetails,
    Actions,
  },

  props: {
    question: {
      type: Object as PropType<FaceValidationProcessor>,
    },
  },

  computed: {
    descriptionItems(): Array<Option> {
      const validation = this.question.face_validation;

      if (!validation) return [];

      const username = validation.username || '';
      const client = username.split('-')[0].substr(0, 24);

      const command = validation.validation_id
        && validation.client_id
        && `/validationdata@BettyABot ${validation.validation_id} ${validation.client_id}`;

      return [
        {
          label: 'Client',
          value: client,
        },
        {
          label: 'Command',
          value: command || '',
        },
      ];
    },

    title(): string {
      if (this.isLiveness) {
        return 'Validación de prueba de vida';
      }

      return 'Validación de rostro';
    },

    isLiveness(): boolean {
      return this.actions.length > 0;
    },

    isPassiveLiveness(): boolean {
      for (let i = 0; i < this.tasks.length; i += 1) {
        if (String(this.tasks[i].key) === ValidationType.PassiveLiveness) return true;
      }

      return false;
    },

    filteredTasks(): Array<Field> {
      if (!this.tasks) {
        return [];
      }

      if (this.tasks.length < 1) {
        return [];
      }

      return this.getFilteredFields();
    },

    actions(): Array<Action> {
      if (!this.question.face_validation) {
        return [];
      }

      if (!this.question.face_validation.actions) {
        return [];
      }

      return this.question.face_validation.actions;
    },

    isVideo(): boolean {
      if (!this.question.object_key_mime_type) {
        return false;
      }

      return this.question.object_key_mime_type.startsWith('video');
    },
  },

  mounted() {
    this.assignmentId = getTurkAssignmentID();

    if (!this.question.tasks) {
      return;
    }

    this.tasks = getTasks(this.question.tasks);
  },

  methods: {
    getFilteredFields(): Array<Field> {
      const fields = [this.tasks[0]];

      for (let i = 1; i < this.tasks.length; i += 1) {
        const prevValue = String(this.tasks[i - 1].value) || 'invalid';

        if (prevValue === `${ValidationType.PassiveLiveness}:valid`) {
          return fields;
        }

        if (
          prevValue !== `${ValidationType.PassiveLiveness}:invalid`
          && prevValue.endsWith('invalid')
        ) {
          return fields;
        }

        fields.push(this.tasks[i]);
      }

      return fields;
    },

    isValid(): boolean {
      const form = this.$refs.form as Form;
      if (!form.validate) {
        return false;
      }

      return form.validate();
    },

    onSubmit() {
      if (!this.isValid()) {
        return;
      }

      const formVue = this.$refs.form as Vue;
      const formElement = formVue.$el as HTMLFormElement;

      formElement.submit();
    },

    isTaskAssigned(): boolean {
      return isTaskAssigned(this.assignmentId);
    },

    submitText(): string {
      return submitText(this.assignmentId);
    },
  },
});
