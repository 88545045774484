/* eslint-disable camelcase */

import { Field, Option } from '@/manual-review/form';
import { Task } from '@/manual-review/tasks';

export type DocumentSide = {
  title?: string;
  fields: Array<Field>;
  qualityQuestion?: Field;
  isQualitySelected?: boolean;
  image?: string;
};

export type Sides = {
  front: DocumentSide;
  reverse: DocumentSide;
};

export type Document = {
  sides: Sides;
};

export enum DocumentType {
  BicycleID = 'bicycle-id',
  BirthCertificate = 'birth-certificate',
  CivilRegistration = 'civil-registration',
  Code = 'code',
  CPF = 'individual-registration',
  Curp = 'curp',
  IdentityCard = 'identity-card',
  DiplomaticID = 'diplomatic-id',
  DNI = 'dni',
  DriverLicense = 'driver-license',
  DUI = 'dui',
  Empty = '',
  Escrow = 'escrow',
  ForeignID = 'foreign-id',
  Invoice = 'invoice',
  ForeignSocieties = 'foreign-societies',
  LicensePlate = 'license-plate',
  Name = 'name',
  NationalID = 'national-id',
  NIS = 'nis',
  NIT = 'nit',
  Nuip = 'nuip',
  Passport = 'passport',
  PEP = 'pep',
  PPT = 'ppt',
  ProfessionalCard = 'professional-card',
  PTP = 'ptp',
  Query = 'query',
  RG = 'general-registration',
  RefugeeID = 'refugee-id',
  RUC = 'ruc',
  RUI = 'rui',
  Rut = 'rut',
  TaxID = 'tax-id',
  StateID = 'state-id',
  VehicleID = 'vehicle-id',
  Auto = 'auto',
  Unknown = 'unknown',
  Certificate = 'certificate',
  CNH = 'cnh',
  PhoneNumber = 'phone-number',
}

export enum DocumentVersion {
  CONationalID2020 = 'co_national-id-2020',
}

export enum Country {
  ALL = 'ALL',
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DE = 'DE',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AN = 'AN',
  SA = 'SA',
  DZ = 'DZ',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  CV = 'CV',
  KY = 'KY',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CF = 'CF',
  TD = 'TD',
  CZ = 'CZ',
  CL = 'CL',
  CN = 'CN',
  CY = 'CY',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CD = 'CD',
  CK = 'CK',
  KP = 'KP',
  KR = 'KR',
  CI = 'CI',
  CR = 'CR',
  HR = 'HR',
  CU = 'CU',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  AE = 'AE',
  ER = 'ER',
  SK = 'SK',
  SI = 'SI',
  ES = 'ES',
  EA = 'EA',
  US = 'US',
  UM = 'UM',
  EE = 'EE',
  ET = 'ET',
  FO = 'FO',
  PH = 'PH',
  FI = 'FI',
  FJ = 'FJ',
  FR = 'FR',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  GS = 'GS',
  GH = 'GH',
  GI = 'GI',
  GD = 'GD',
  GR = 'GR',
  GL = 'GL',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GF = 'GF',
  GG = 'GG',
  GN = 'GN',
  GQ = 'GQ',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IS = 'IS',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KG = 'KG',
  KI = 'KI',
  KW = 'KW',
  LA = 'LA',
  LS = 'LS',
  LV = 'LV',
  LB = 'LB',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MK = 'MK',
  MG = 'MG',
  MY = 'MY',
  MW = 'MW',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  FK = 'FK',
  IM = 'IM',
  MP = 'MP',
  MA = 'MA',
  MH = 'MH',
  MQ = 'MQ',
  MU = 'MU',
  MR = 'MR',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  CX = 'CX',
  NP = 'NP',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  NO = 'NO',
  NC = 'NC',
  NZ = 'NZ',
  OM = 'OM',
  NL = 'NL',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PN = 'PN',
  PF = 'PF',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  GB = 'GB',
  RE = 'RE',
  RW = 'RW',
  RO = 'RO',
  RU = 'RU',
  EH = 'EH',
  SB = 'SB',
  WS = 'WS',
  AS = 'AS',
  KN = 'KN',
  SM = 'SM',
  PM = 'PM',
  VC = 'VC',
  SH = 'SH',
  LC = 'LC',
  ST = 'ST',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SY = 'SY',
  SO = 'SO',
  LK = 'LK',
  SZ = 'SZ',
  ZA = 'ZA',
  SD = 'SD',
  SE = 'SE',
  CH = 'CH',
  SR = 'SR',
  SJ = 'SJ',
  TH = 'TH',
  TW = 'TW',
  TZ = 'TZ',
  TJ = 'TJ',
  IO = 'IO',
  TF = 'TF',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TC = 'TC',
  TM = 'TM',
  TR = 'TR',
  TV = 'TV',
  UA = 'UA',
  UG = 'UG',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VA = 'VA',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  YE = 'YE',
  DJ = 'DJ',
  ZM = 'ZM',
  ZW = 'ZW',
}

export const CountryAlpha3: Array<Option> = [
  { label: 'ABW - Aruba', value: 'ABW' },
  { label: 'AFG - Afganistán', value: 'AFG' },
  { label: 'AGO - Angola', value: 'AGO' },
  { label: 'AIA - Anguila', value: 'AIA' },
  { label: 'ALA - Åland, Islas', value: 'ALA' },
  { label: 'ALB - Albania', value: 'ALB' },
  { label: 'AND - Andorra', value: 'AND' },
  { label: 'ARE - Emiratos Árabes Unidos (los)', value: 'ARE' },
  { label: 'ARG - Argentina', value: 'ARG' },
  { label: 'ARM - Armenia', value: 'ARM' },
  { label: 'ASM - Samoa Americana', value: 'ASM' },
  { label: 'ATA - Antártida', value: 'ATA' },
  { label: 'ATF - Tierras Australes Francesas (las)', value: 'ATF' },
  { label: 'ATG - Antigua y Barbuda', value: 'ATG' },
  { label: 'AUS - Australia', value: 'AUS' },
  { label: 'AUT - Austria', value: 'AUT' },
  { label: 'AZE - Azerbaiyán', value: 'AZE' },
  { label: 'BDI - Burundi', value: 'BDI' },
  { label: 'BEL - Bélgica', value: 'BEL' },
  { label: 'BEN - Benín', value: 'BEN' },
  { label: 'BES - Bonaire, San Eusatquio y Saba', value: 'BES' },
  { label: 'BFA - Burkina Faso', value: 'BFA' },
  { label: 'BGD - Bangladés', value: 'BGD' },
  { label: 'BGR - Bulgaria', value: 'BGR' },
  { label: 'BHR - Baréin', value: 'BHR' },
  { label: 'BHS - Bahamas (las)', value: 'BHS' },
  { label: 'BIH - Bosnia y Herzegovina', value: 'BIH' },
  { label: 'BLM - San Barthélemy', value: 'BLM' },
  { label: 'BLR - Belarús', value: 'BLR' },
  { label: 'BLZ - Belice', value: 'BLZ' },
  { label: 'BMU - Bermuda', value: 'BMU' },
  { label: 'BOL - Bolivia (Estado Plurinacional de)', value: 'BOL' },
  { label: 'BRA - Brasil', value: 'BRA' },
  { label: 'BRB - Barbados', value: 'BRB' },
  { label: 'BRN - Brunei Darussalam', value: 'BRN' },
  { label: 'BTN - Bhután', value: 'BTN' },
  { label: 'BVT - Bouvet, Isla', value: 'BVT' },
  { label: 'BWA - Botsuana', value: 'BWA' },
  { label: 'CAF - República Centroafricana (la)', value: 'CAF' },
  { label: 'CAN - Canadá', value: 'CAN' },
  { label: 'CCK - Cocos / Keeling, (las) Islas', value: 'CCK' },
  { label: 'CHE - Suiza', value: 'CHE' },
  { label: 'CHL - Chile', value: 'CHL' },
  { label: 'CHN - China', value: 'CHN' },
  { label: "CIV - Côte d'Ivoire", value: 'CIV' },
  { label: 'CMR - Camerún', value: 'CMR' },
  { label: 'COD - Congo (la República Democrática del)', value: 'COD' },
  { label: 'COG - Congo (el)', value: 'COG' },
  { label: 'COK - Cook, (las) Islas', value: 'COK' },
  { label: 'COL - Colombia', value: 'COL' },
  { label: 'COM - Comoras (las)', value: 'COM' },
  { label: 'CPV - Cabo Verde', value: 'CPV' },
  { label: 'CRI - Costa Rica', value: 'CRI' },
  { label: 'CUB - Cuba', value: 'CUB' },
  { label: 'CUW - Curazao', value: 'CUW' },
  { label: 'CXR - Christmas, Isla', value: 'CXR' },
  { label: 'CYM - Caimán, (las) Islas', value: 'CYM' },
  { label: 'CYP - Chipre', value: 'CYP' },
  { label: 'CZE - Chequia', value: 'CZE' },
  { label: 'DEU - Alemania', value: 'DEU' },
  { label: 'DJI - Djibouti', value: 'DJI' },
  { label: 'DMA - Dominica', value: 'DMA' },
  { label: 'DNK - Dinamarca', value: 'DNK' },
  { label: 'DOM - Dominicana, (la) República', value: 'DOM' },
  { label: 'DZA - Argelia', value: 'DZA' },
  { label: 'ECU - Ecuador', value: 'ECU' },
  { label: 'EGY - Egipto', value: 'EGY' },
  { label: 'ERI - Eritrea', value: 'ERI' },
  { label: 'ESH - Sáhara Occidental', value: 'ESH' },
  { label: 'ESP - España', value: 'ESP' },
  { label: 'EST - Estonia', value: 'EST' },
  { label: 'ETH - Etiopía', value: 'ETH' },
  { label: 'FIN - Finlandia', value: 'FIN' },
  { label: 'FJI - Fiji', value: 'FJI' },
  { label: 'FLK - Malvinas [Falkland], (las) Islas', value: 'FLK' },
  { label: 'FRA - Francia', value: 'FRA' },
  { label: 'FRO - Feroe, (las) Islas', value: 'FRO' },
  { label: 'FSM - Micronesia (Estados Federados de)', value: 'FSM' },
  { label: 'GAB - Gabón', value: 'GAB' },
  { label: 'GBR - Reino Unido de Gran Bretaña e Irlanda del Norte', value: 'GBR' },
  { label: 'GEO - Georgia', value: 'GEO' },
  { label: 'GGY - Guernsey', value: 'GGY' },
  { label: 'GHA - Ghana', value: 'GHA' },
  { label: 'GIB - Gibraltar', value: 'GIB' },
  { label: 'GIN - Guinea', value: 'GIN' },
  { label: 'GLP - Guadalupe', value: 'GLP' },
  { label: 'GMB - Gambia (la)', value: 'GMB' },
  { label: 'GNB - Guinea-Bissau', value: 'GNB' },
  { label: 'GNQ - Guinea Ecuatorial', value: 'GNQ' },
  { label: 'GRC - Grecia', value: 'GRC' },
  { label: 'GRD - Granada', value: 'GRD' },
  { label: 'GRL - Groenlandia', value: 'GRL' },
  { label: 'GTM - Guatemala', value: 'GTM' },
  { label: 'GUF - Guayana Francesa', value: 'GUF' },
  { label: 'GUM - Guam', value: 'GUM' },
  { label: 'GUY - Guyana', value: 'GUY' },
  { label: 'HKG - Hong Kong', value: 'HKG' },
  { label: 'HMD - Heard (Isla) e Islas McDonald', value: 'HMD' },
  { label: 'HND - Honduras', value: 'HND' },
  { label: 'HRV - Croacia', value: 'HRV' },
  { label: 'HTI - Haití', value: 'HTI' },
  { label: 'HUN - Hungría', value: 'HUN' },
  { label: 'IDN - Indonesia', value: 'IDN' },
  { label: 'IMN - Isla de Man', value: 'IMN' },
  { label: 'IND - India', value: 'IND' },
  { label: 'IOT - Territorio Británico del Océano Índico (el)', value: 'IOT' },
  { label: 'IRL - Irlanda', value: 'IRL' },
  { label: 'IRN - Irán (República Islámica de)', value: 'IRN' },
  { label: 'IRQ - Iraq', value: 'IRQ' },
  { label: 'ISL - Islandia', value: 'ISL' },
  { label: 'ISR - Israel', value: 'ISR' },
  { label: 'ITA - Italia', value: 'ITA' },
  { label: 'JAM - Jamaica', value: 'JAM' },
  { label: 'JEY - Jersey', value: 'JEY' },
  { label: 'JOR - Jordania', value: 'JOR' },
  { label: 'JPN - Japón', value: 'JPN' },
  { label: 'KAZ - Kazajstán', value: 'KAZ' },
  { label: 'KEN - Kenya', value: 'KEN' },
  { label: 'KGZ - Kirguistán', value: 'KGZ' },
  { label: 'KHM - Camboya', value: 'KHM' },
  { label: 'KIR - Kiribati', value: 'KIR' },
  { label: 'KNA - Saint Kitts y Nevis', value: 'KNA' },
  { label: 'KOR - Corea (la República de)', value: 'KOR' },
  { label: 'KWT - Kuwait', value: 'KWT' },
  { label: 'LAO - Lao, (la) República Democrática Popular', value: 'LAO' },
  { label: 'LBN - Líbano', value: 'LBN' },
  { label: 'LBR - Liberia', value: 'LBR' },
  { label: 'LBY - Libia', value: 'LBY' },
  { label: 'LCA - Santa Lucía', value: 'LCA' },
  { label: 'LIE - Liechtenstein', value: 'LIE' },
  { label: 'LKA - Sri Lanka', value: 'LKA' },
  { label: 'LSO - Lesotho', value: 'LSO' },
  { label: 'LTU - Lituania', value: 'LTU' },
  { label: 'LUX - Luxemburgo', value: 'LUX' },
  { label: 'LVA - Letonia', value: 'LVA' },
  { label: 'MAC - Macao', value: 'MAC' },
  { label: 'MAF - San Martín (parte francesa)', value: 'MAF' },
  { label: 'MAR - Marruecos', value: 'MAR' },
  { label: 'MCO - Mónaco', value: 'MCO' },
  { label: 'MDA - Moldova (la República de)', value: 'MDA' },
  { label: 'MDG - Madagascar', value: 'MDG' },
  { label: 'MDV - Maldivas', value: 'MDV' },
  { label: 'MEX - México', value: 'MEX' },
  { label: 'MHL - Marshall, (las) Islas', value: 'MHL' },
  { label: 'MKD - Macedonia del Norte', value: 'MKD' },
  { label: 'MLI - Malí', value: 'MLI' },
  { label: 'MLT - Malta', value: 'MLT' },
  { label: 'MMR - Myanmar', value: 'MMR' },
  { label: 'MNE - Montenegro', value: 'MNE' },
  { label: 'MNG - Mongolia', value: 'MNG' },
  { label: 'MNP - Marianas Septentrionales, (las) Islas', value: 'MNP' },
  { label: 'MOZ - Mozambique', value: 'MOZ' },
  { label: 'MRT - Mauritania', value: 'MRT' },
  { label: 'MSR - Montserrat', value: 'MSR' },
  { label: 'MTQ - Martinica', value: 'MTQ' },
  { label: 'MUS - Mauricio', value: 'MUS' },
  { label: 'MWI - Malawi', value: 'MWI' },
  { label: 'MYS - Malasia', value: 'MYS' },
  { label: 'MYT - Mayotte', value: 'MYT' },
  { label: 'NAM - Namibia', value: 'NAM' },
  { label: 'NCL - Nueva Caledonia', value: 'NCL' },
  { label: 'NER - Níger (el)', value: 'NER' },
  { label: 'NFK - Norfolk, Isla', value: 'NFK' },
  { label: 'NGA - Nigeria', value: 'NGA' },
  { label: 'NIC - Nicaragua', value: 'NIC' },
  { label: 'NIU - Niue', value: 'NIU' },
  { label: 'NLD - Países Bajos (los)', value: 'NLD' },
  { label: 'NOR - Noruega', value: 'NOR' },
  { label: 'NPL - Nepal', value: 'NPL' },
  { label: 'NRU - Nauru', value: 'NRU' },
  { label: 'NZL - Nueva Zelandia', value: 'NZL' },
  { label: 'OMN - Omán', value: 'OMN' },
  { label: 'PAK - Pakistán', value: 'PAK' },
  { label: 'PAN - Panama', value: 'PAN' },
  { label: 'PCN - Pitcairn', value: 'PCN' },
  { label: 'PER - Perú', value: 'PER' },
  { label: 'PHL - Filipinas (las)', value: 'PHL' },
  { label: 'PLW - Palau', value: 'PLW' },
  { label: 'PNG - Papua Nueva Guinea', value: 'PNG' },
  { label: 'POL - Polonia', value: 'POL' },
  { label: 'PRI - Puerto Rico', value: 'PRI' },
  { label: 'PRK - Corea (la República Popular Democrática de)', value: 'PRK' },
  { label: 'PRT - Portugal', value: 'PRT' },
  { label: 'PRY - Paraguay', value: 'PRY' },
  { label: 'PSE - Palestina, Estado de', value: 'PSE' },
  { label: 'PYF - Polinesia Francesa', value: 'PYF' },
  { label: 'QAT - Qatar', value: 'QAT' },
  { label: 'REU - Reunión', value: 'REU' },
  { label: 'ROU - Rumania', value: 'ROU' },
  { label: 'RUS - Rusia, (la) Federación de', value: 'RUS' },
  { label: 'RWA - Rwanda', value: 'RWA' },
  { label: 'SAU - Arabia Saudita', value: 'SAU' },
  { label: 'SDN - Sudán', value: 'SDN' },
  { label: 'SEN - Senegal', value: 'SEN' },
  { label: 'SGP - Singapur', value: 'SGP' },
  { label: 'SGS - Georgia del Sur (la) y las Islas Sandwich del Sur', value: 'SGS' },
  { label: 'SHN - Santa Elena, Ascensión y Tristán de Acuña', value: 'SHN' },
  { label: 'SJM - Svalbard y Jan Mayen', value: 'SJM' },
  { label: 'SLB - Salomón, Islas', value: 'SLB' },
  { label: 'SLE - Sierra Leona', value: 'SLE' },
  { label: 'SLV - El Salvador', value: 'SLV' },
  { label: 'SMR - San Marino', value: 'SMR' },
  { label: 'SOM - Somalia', value: 'SOM' },
  { label: 'SPM - San Pedro y Miquelón', value: 'SPM' },
  { label: 'SRB - Serbia', value: 'SRB' },
  { label: 'SSD - Sudán del Sur', value: 'SSD' },
  { label: 'STP - Santo Tomé y Príncipe', value: 'STP' },
  { label: 'SUR - Suriname', value: 'SUR' },
  { label: 'SVK - Eslovaquia', value: 'SVK' },
  { label: 'SVN - Eslovenia', value: 'SVN' },
  { label: 'SWE - Suecia', value: 'SWE' },
  { label: 'SWZ - Eswatini', value: 'SWZ' },
  { label: 'SXM - San Martín (parte neerlandesa)', value: 'SXM' },
  { label: 'SYC - Seychelles', value: 'SYC' },
  { label: 'SYR - República Árabe Siria', value: 'SYR' },
  { label: 'TCA - Turcas y Caicos, (las) Islas', value: 'TCA' },
  { label: 'TCD - Chad', value: 'TCD' },
  { label: 'TGO - Togo', value: 'TGO' },
  { label: 'THA - Tailandia', value: 'THA' },
  { label: 'TJK - Tayikistán', value: 'TJK' },
  { label: 'TKL - Tokelau', value: 'TKL' },
  { label: 'TKM - Turkmenistán', value: 'TKM' },
  { label: 'TLS - Timor-Leste', value: 'TLS' },
  { label: 'TON - Tonga', value: 'TON' },
  { label: 'TTO - Trinidad y Tabago', value: 'TTO' },
  { label: 'TUN - Túnez', value: 'TUN' },
  { label: 'TUR - Turquía', value: 'TUR' },
  { label: 'TUV - Tuvalu', value: 'TUV' },
  { label: 'TWN - Taiwán (ProvincIa de China)', value: 'TWN' },
  { label: 'TZA - Tanzania, República Unida de', value: 'TZA' },
  { label: 'UGA - Uganda', value: 'UGA' },
  { label: 'UKR - Ucrania', value: 'UKR' },
  { label: 'UMI - Islas menores alejadas de Estados Unidos (las)', value: 'UMI' },
  { label: 'URY - Uruguay', value: 'URY' },
  { label: 'USA - Estados Unidos de America (los)', value: 'USA' },
  { label: 'UZB - Uzbekistán', value: 'UZB' },
  { label: 'VAT - Santa Sede (la)', value: 'VAT' },
  { label: 'VCT - San Vincente y las Granadinas', value: 'VCT' },
  { label: 'VEN - Venezuela (República Bolivariana de)', value: 'VEN' },
  { label: 'VGB - Vírgenes Británicas, Islas', value: 'VGB' },
  { label: 'VIR - Vírgenes de los Estados Unidos, Islas', value: 'VIR' },
  { label: 'VNM - Viet Nam', value: 'VNM' },
  { label: 'VUT - Vanuatu', value: 'VUT' },
  { label: 'WLF - Wallis y Futuna', value: 'WLF' },
  { label: 'WSM - Samoa', value: 'WSM' },
  { label: 'YEM - Yemen', value: 'YEM' },
  { label: 'ZAF - Sudáfrica', value: 'ZAF' },
  { label: 'ZMB - Zambia', value: 'ZMB' },
  { label: 'ZWE - Zimbabue', value: 'ZWE' },
];

export type MapDocumentType = {
  [country in Country]?: {
    [documentType in DocumentType]?: Document;
  };
};

export enum CheckType {
  Company = 'company',
  Person = 'person',
  Vehicle = 'vehicle',
  Validation = 'validation',
  DocumentValidation = 'document-validation',
}

export enum BackendDocumentCountryKey {
  Mexico = 'mexico_document',
  Brazil = 'brazil_document',
  Chile = 'chile_document',
}

export type DocsCopyFailure = {
  message: string;
  fraud: boolean;
};

export type RG = {
  issue_date: string;
  issue_state: string;
  issuer: string;
  rg_number: string;
  reference_document: string;
};

export type CPF = {
  issue_date: string;
  cpf_number: string;
};

export type CNH = {
  cnh_number: string;
  category: string;
  expiration_date: string;
  first_driver_license_date: string;
  is_permit: string;
  issue_date: string;
  issue_state: string;
  motorcycle_permit: string;
  notes: string;
  renach_number: string;
  security_number: string;
  validation_number: string;
};

export type BrazilDocument = {
  id?: string;
  caf_declined_reasons?: { [index: number]: DocsCopyFailure };
  is_documentscopy_ready?: boolean;
  cnh?: CNH;
  cpf?: CPF;
  rg?: RG;
};

export type ChileDocument = {
  run: string;
};

export enum Side {
  Front = 'front',
  Reverse = 'reverse',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export enum Status {
  InProgress = 'in_progress',
  Completed = 'completed',
  Pending = 'pending',
  Failed = 'failed',
  NotStarted = 'not_started',
}

export type MexicoDocument = {
  municipality: number;
  municipality_name: string;
  state: number;
  state_name: string;
  locality: number;
  section: number;
  elector_key: string;
  issue_number: string;
  ocr: string;
  cic: string;
  citizen_id: string;
  neighborhood: string;
  interior_number: string;
  exterior_number: string;
};

export type PassportDocument = {
  passport_type: string;
  nationality: string;
  authority: string;
  emitter_country: string;
};

export enum VerificationResult {
  Valid = 'valid',
  Invalid = 'invalid',
  Unknown = 'unknown',
}

export type ValidationResult = {
  validation_name: string;
  result: VerificationResult;
  status: Status;
  message: string;
};

export interface BackendDocument {
  account_id?: string;
  birth_place?: string;
  brazil_document?: BrazilDocument;
  check_id?: string;
  check_type?: CheckType;
  chile_document?: ChileDocument;
  civil_status?: string;
  client_id?: string;
  confidence?: number;
  country: Country;
  creation_date?: string;
  date_of_birth?: string;
  depth?: number;
  district?: string;
  doc_id?: string;
  document_front_url?: string;
  document_number?: string;
  document_reverse_url?: string;
  document_side?: Side;
  document_type: DocumentType;
  document_version?: string;
  expedition_date?: string;
  expedition_place?: string;
  expiration_date?: string;
  father_name?: string;
  front_side_processed?: boolean;
  gender?: Gender;
  height?: string;
  issue_date?: string;
  last_name?: string;
  link_fingerprint?: string;
  link_photo?: string;
  link_signature?: string;
  machine_readable?: string;
  mexico_document?: MexicoDocument;
  mother_name?: string;
  name?: string;
  nationality?: string;
  national_registrar?: string;
  foreign_id?: string;
  passport_document?: PassportDocument;
  perform_check?: boolean;
  perform_document_verification?: boolean;
  production_data?: string;
  province?: string;
  rh?: string;
  registration_date?: string;
  residence_address?: string;
  reverse_side_processed?: boolean;
  state?: string;
  state_name?: string;
  status?: Status;
  update_date?: string;
  username?: string;
  billing_hub?: string;
  validation_id?: string;
  validation_result?: VerificationResult;
  validation_results?: { [index: string]: ValidationResult };
  validation_status?: Status;
  country_validation_status_key?: string;
  street?: string;
  postal_code?: string;
  pay_limit_date?: string;
}

export interface DocumentQuestion {
  external_submit?: string;
  tasks?: Array<Task>;
  document?: BackendDocument;
  hit_form_url?: string;
  front_fail_reasons?: Array<string>;
  reverse_fail_reasons?: Array<string>;
}
