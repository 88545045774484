/* eslint-disable */
import brNationalID from "@/documents-validation/br_national_id";
import clNationalID from "@/documents-validation/cl_national_id";
import coForeignID from "@/documents-validation/co_foreign_id";
import coNationalID from "@/documents-validation/co_national_id";
import coPassport from "@/documents-validation/co_passport";
import coPEP from "@/documents-validation/co_pep";
import coPPT from "@/documents-validation/co_ppt";
import crForeignID from "@/documents-validation/cr_foreign_id";
import crNationalID from "@/documents-validation/cr_national_id";
import veNationalID from "@/documents-validation/ve_national_id";
import mxNationalID from "@/documents-validation/mx_national_id";
import mxForeignID from "@/documents-validation/mx_foreign_id";
import mxInvoice from "@/documents-validation/mx_invoice";
import passport from "@/documents-validation/passport";
import peNationalID from "@/documents-validation/pe_national_id";
import peForeignID from "@/documents-validation/pe_foreign_id";
import defaultDocument from "@/documents-validation/default_id";
import { getOptionValueByLabel, parseMRZDate } from "@/documents-validation/shared_funtions";
import { Field, FieldType, Option } from "@/manual-review/form";
import {
  Document,
  DocumentQuestion,
  MapDocumentType,
  BackendDocument,
  MexicoDocument,
  BackendDocumentCountryKey,
  Country,
  DocumentType,
} from "./types";
import { isOptionalFieldsActive, coReverseOptionalFields } from "./optional_fields";

const mapDocuments: MapDocumentType = {
  [Country.BR]: {
    [DocumentType.CNH]: brNationalID,
  },
  [Country.CL]: {
    [DocumentType.NationalID]: clNationalID,
  },
  [Country.CO]: {
    [DocumentType.ForeignID]: coForeignID,
    [DocumentType.NationalID]: coNationalID,
    [DocumentType.Passport]: coPassport,
    [DocumentType.PEP]: coPEP,
    [DocumentType.PPT]: coPPT,
  },
  [Country.CR]: {
    [DocumentType.ForeignID]: crForeignID,
    [DocumentType.NationalID]: crNationalID,
  },
  [Country.VE]: {
    [DocumentType.NationalID]: veNationalID,
  },
  [Country.MX]: {
    [DocumentType.NationalID]: mxNationalID,
    [DocumentType.ForeignID]: mxForeignID,
    [DocumentType.Invoice]: mxInvoice,
  },
  [Country.ALL]: {
    [DocumentType.Passport]: passport,
  },
  [Country.PE]: {
    [DocumentType.NationalID]: peNationalID,
    [DocumentType.ForeignID]: peForeignID,
  },
};

function setPassportMRZValues(mrz: string, field: Field): void {
  if (!field.passportMRZFields) {
    return;
  }

  const {
    issuingCountry,
    lastName,
    name,
    passportNumber,
    nationality,
    dateOfBirth,
    gender,
    expirationDate,
    personalNumber,
  } = field.passportMRZFields;

  if (issuingCountry.options) {
    issuingCountry.value = mrz.substring(2, 5);
  }

  const fullNameParts = mrz.substring(5, 44).split("<<");
  lastName.value = fullNameParts[0].replace(/<+/g, " ").trim();

  if (fullNameParts.length >= 2) {
    name.value = fullNameParts[1].replace(/<+/g, " ").trim();
  }

  passportNumber.value = mrz.substring(44, 53).replace(/<+/g, "");

  if (nationality.options) {
    nationality.value = mrz.substring(54, 57);
  }

  dateOfBirth.value = parseMRZDate(mrz.substring(57, 63), true);

  if (gender.options) {
    gender.value = getOptionValueByLabel(mrz.substring(64, 65), gender.options);
  }

  expirationDate.value = parseMRZDate(mrz.substring(65, 71), false);

  personalNumber.value = mrz.substring(72, 86).replace(/<+/g, "");
}

function setTd1MRZValues(mrz: string, field: Field): void {
  if (!field.td1MRZFields) {
    return;
  }

  const {
    issuingCountry,
    documentNumber,
    nationality,
    dateOfBirth,
    gender,
    expirationDate,
    lastName,
    name,
  } = field.td1MRZFields;

  if (issuingCountry.options) {
    issuingCountry.value = mrz.substring(2, 5);
  }

  documentNumber.value = mrz.substring(5, 14).replace(/<+/g, "");

  dateOfBirth.value = parseMRZDate(mrz.substring(30, 36), true);

  if (gender.options) {
    gender.value = getOptionValueByLabel(mrz.substring(37, 38), gender.options);
  }

  expirationDate.value = parseMRZDate(mrz.substring(38, 44), false);

  if (nationality.options) {
    nationality.value = mrz.substring(45, 48);
  }

  const fullNameParts = mrz.substring(60, 90).split("<<");
  lastName.value = fullNameParts[0].replace(/<+/g, " ").trim();

  if (fullNameParts.length >= 2) {
    name.value = fullNameParts[1].replace(/<+/g, " ").trim();
  }
}

const dateRegexp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/;

function setBasicTypesFieldValues(
  field: Field,
  value: BackendDocument[keyof BackendDocument]
): void {
  const processField = field;
  const stringFieldValue = String(value);

  if (processField.type === FieldType.Date && dateRegexp.test(stringFieldValue)) {
    const [fieldValue] = String(value).split("T");
    processField.value = fieldValue;

    return;
  }

  if (typeof value === "string") {
    processField.value = stringFieldValue;
  }

  if (typeof value === "number") {
    processField.value = Number(value);
  }
}

function setFieldValues(field: Field, value: BackendDocument[keyof BackendDocument]): void {
  const processField = field;
  const stringFieldValue = String(value);

  if (processField.type === FieldType.PassportMRZ) {
    setPassportMRZValues(stringFieldValue, processField);

    return;
  }

  if (processField.type === FieldType.Td1MRZ) {
    setTd1MRZValues(stringFieldValue, processField);

    return;
  }

  setBasicTypesFieldValues(field, value);
}

export function setFieldsValues(fields: Array<Field>, document: BackendDocument): void {
  for (let i = 0; i < fields.length; i += 1) {
    const field = fields[i];
    let value = document[field.name as keyof BackendDocument];

    const [countryKey, key] = field.name.split(".");

    if (countryKey !== "" && key !== "") {
      switch (countryKey) {
        case BackendDocumentCountryKey.Mexico:
          value = document[countryKey as keyof BackendDocument];
          value = value as MexicoDocument;
          value = value[key as keyof MexicoDocument];
          break;
        default:
          break;
      }
    }

    setFieldValues(field, value);
  }
}

const qualityField: Field = {
  label: "Selecciona solo si el documento cumple con alguna característica:",
  value: "",
  rules: [],
  type: FieldType.Select,
  name: "",
};

export function getQualityOptionsField(name: string, qualities: Array<string>): Field {
  const options: Array<Option> = [
    {
      label: "El documento es legible",
      value: "",
    },
  ];

  for (let i = 0; i < qualities.length; i += 1) {
    const quality = qualities[i];
    const option: Option = {
      label: quality,
      value: quality,
    };

    options.push(option);
  }

  return {
    ...qualityField,
    name,
    options,
  };
}

export function setQualityOptionsField(
  name: string,
  fields: Array<Field>,
  qualities: Array<string> | undefined
): void {
  if (!fields || fields.length === 0 || !qualities) {
    return;
  }

  const [firstField] = fields;
  if (firstField.name === name) {
    return;
  }

  const quality = getQualityOptionsField(name, qualities);

  fields.unshift(quality);
}

export function getDocument(question: DocumentQuestion): Document {
  if (!question.document) {
    return defaultDocument;
  }

  const docCountry = mapDocuments[question.document.country];
  if (!docCountry) {
    return defaultDocument;
  }

  const document = docCountry[question.document.document_type];
  if (!document) {
    return defaultDocument;
  }

  // HOTFIX: Enable production_data and national_registrar fields for FGS
  if (isOptionalFieldsActive(question.document)) {
    document.sides.reverse.fields = document.sides.reverse.fields.concat(coReverseOptionalFields);
  }

  setFieldsValues(document.sides.front.fields, question.document);
  setFieldsValues(document.sides.reverse.fields, question.document);
  setQualityOptionsField(
    "front_quality_features",
    document.sides.front.fields,
    question.front_fail_reasons
  );
  setQualityOptionsField(
    "reverse_quality_features",
    document.sides.reverse.fields,
    question.reverse_fail_reasons
  );

  document.sides.front = {
    ...document.sides.front,
    title: "Frente",
    image: question.document.document_front_url,
  };

  document.sides.reverse = {
    ...document.sides.reverse,
    title: "Reverso",
    image: question.document.document_reverse_url,
  };

  return document;
}

export default {
  getDocument,
  getQualityOptionsField,
};
