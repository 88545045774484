
import Vue from 'vue';
import BaseInput from '@/components/BaseInput.vue';
import PassportMrzInput from '@/components/PassportMrzInput.vue';
import IdOneMrzInput from '@/components/IdOneMrzInput.vue';
import { Field, FieldType } from '@/manual-review/form';
import { isType, isBaseType } from '@/documents-validation/shared_funtions';

export default Vue.extend({
  components: { BaseInput, PassportMrzInput, IdOneMrzInput },

  props: {
    field: {
      type: Object as () => Field,
    },
  },

  computed: {
    isBaseInput(): boolean {
      return isBaseType(this.field);
    },
    isPassportMRZ(): boolean {
      if (!this.field || !this.field.passportMRZFields) {
        return false;
      }

      return isType(this.field, FieldType.PassportMRZ);
    },
    isTd1MRZ(): boolean {
      if (!this.field || !this.field.td1MRZFields) {
        return false;
      }

      return isType(this.field, FieldType.Td1MRZ);
    },
  },
});
