
import Vue from 'vue';
import BaseInput from '@/components/BaseInput.vue';
import { Field } from '@/manual-review/form';
import { passportNumberMaxLength, personalNumberMaxLength } from '@/documents-validation/passport';

function completeFieldLength(value: string, length: number): string {
  return value.padEnd(length, '<').substring(0, length);
}

function parseNameField(field: string): string {
  return field.toUpperCase().replace(/ +/g, ' ').trim().replace(/ /g, '<');
}

const expectedDateParts = 3;

function parseDateField(field: string): string {
  const dateParts = field.split('-');

  if (dateParts.length !== expectedDateParts) {
    return '<<<<<<';
  }

  return dateParts[0].slice(-2) + dateParts[1] + dateParts[2]; // Format: YYMMDD
}

// Algorithm used to calculate the check digit: http://www.highprogrammer.com/alan/numbers/mrp.html#checkdigit
function calculateCheckDigit(value: string): number {
  let digit = 0;
  const factors = [7, 3, 1];

  for (let i = 0; i < value.length; i += 1) {
    let charCode = value.charCodeAt(i);

    if (charCode === 60) charCode = 0; // <
    if (charCode >= 65) charCode -= 55; // A - Z
    if (charCode >= 48) charCode -= 48; // 0 - 9

    charCode *= factors[i % 3];
    digit += charCode;
  }

  digit %= 10;

  return digit;
}

export default Vue.extend({
  components: { BaseInput },

  props: {
    field: {
      type: Object as () => Field,
    },
  },

  computed: {
    // MRZ structure: https://en.wikipedia.org/wiki/Machine-readable_passport#Passport_booklets
    getPassportMRZ(): string {
      if (!this.field || !this.field.passportMRZFields) {
        return '';
      }

      const {
        issuingCountry, lastName, name, passportNumber, nationality,
        dateOfBirth, gender, expirationDate, personalNumber,
      } = this.field.passportMRZFields;

      // First row
      let mrz = 'P<'; // P for passport and < for default type

      mrz += completeFieldLength(String(issuingCountry.value), 3);

      const fullName = `${parseNameField(String(lastName.value))}<<${parseNameField(String(name.value))}`;
      mrz += completeFieldLength(fullName, 39);

      // Second row
      let passportNumberWithCheckDigit = completeFieldLength(
        String(passportNumber.value),
        passportNumberMaxLength,
      );
      passportNumberWithCheckDigit += calculateCheckDigit(passportNumberWithCheckDigit);
      mrz += passportNumberWithCheckDigit;

      mrz += completeFieldLength(String(nationality.value), 3);

      const parsedDateOfBirth = parseDateField(String(dateOfBirth.value));
      const dateOfBirthWithCheckDigit = parsedDateOfBirth + calculateCheckDigit(parsedDateOfBirth);
      mrz += dateOfBirthWithCheckDigit;

      mrz += completeFieldLength(String(gender.value), 1).toUpperCase();

      const parsedExpirationDate = parseDateField(String(expirationDate.value));
      const expirationDateWithCheckDigit = (
        parsedExpirationDate + calculateCheckDigit(parsedExpirationDate)
      );
      mrz += expirationDateWithCheckDigit;

      let personalNumberWithCheckDigit = completeFieldLength(
        String(personalNumber.value),
        personalNumberMaxLength,
      );
      personalNumberWithCheckDigit += calculateCheckDigit(personalNumberWithCheckDigit);
      mrz += personalNumberWithCheckDigit;

      mrz += calculateCheckDigit(
        passportNumberWithCheckDigit
        + dateOfBirthWithCheckDigit
        + expirationDateWithCheckDigit
        + personalNumberWithCheckDigit,
      );

      return mrz;
    },
  },
});
