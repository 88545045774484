import { FieldType } from '@/manual-review/form';

import validate from '@/manual-review/validations';
import {
  Document,
} from './types';

const foreignIDRegexp = /^[0-9]{9}$/;

export function validateForeignIDNumber(value: string): string | boolean {
  if (!foreignIDRegexp.test(value)) {
    return 'Carné de extranjería debe tener 9 dígitos';
  }

  return true;
}

const doc: Document = {
  sides: {
    front: {
      fields: [
        {
          type: FieldType.Select,
          name: 'document_version',
          label: 'Versión de documento',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: '2019', value: 'pe_foreign-id-2019' },
            { label: '2022', value: 'pe_foreign-id-2022' },
          ],
          alwaysRequired: true,
        },
        {
          type: FieldType.Text,
          name: 'document_number',
          label: 'Carné de extranjería',
          rules: [validate.isRequired, validateForeignIDNumber],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'name',
          label: 'Nombres',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'last_name',
          label: 'Apellidos',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Select,
          name: 'gender',
          label: 'Sexo',
          rules: [],
          value: '',
          options: [
            { label: 'M', value: 'male' },
            { label: 'F', value: 'female' },
          ],
        },
        {
          type: FieldType.Date,
          name: 'date_of_birth',
          label: 'Nacimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
          allowedDates: validate.dateOfBirth,
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'civil_status',
          label: 'Estado Civil',
          rules: [],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'status',
          label: 'Calidad Migratoria',
          rules: [],
          value: '',
        },
      ],
    },
    reverse: {
      fields: [
        {
          type: FieldType.Text,
          name: 'residence_address',
          label: 'Dirección',
          rules: [validate.place],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'state_name',
          label: 'Departamento',
          rules: [validate.place],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'province',
          label: 'Provincia',
          rules: [validate.place],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'district',
          label: 'Distrito',
          rules: [validate.place],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'foreign_id',
          label: 'N° Pasaporte / Documento de viaje',
          rules: [],
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'expiration_date',
          label: 'Caducidad C.E.',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpiration],
          allowedDates: validate.dateOfExpiration,
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'expedition_date',
          label: 'Fec. Emisión',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpedition],
          allowedDates: validate.dateOfExpedition,
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'registration_date',
          label: 'Fec. Inscripción',
          rules: [validate.isDate, validate.dateOfRegistration],
          allowedDates: validate.dateOfRegistration,
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'expedition_place',
          label: 'Lug. Expedición',
          rules: [],
          value: '',
        },
      ],
    },
  },
};

export default doc;
