import {
  Option,
  Field,
  FieldType,
  BaseFieldTypes,
} from '@/manual-review/form';

export const isType = (field: Field, fieldType: FieldType): boolean => {
  if (!field) {
    return false;
  }

  return field.type === fieldType;
};

export const isBaseType = (field: Field): boolean => {
  for (let i = 0; i < BaseFieldTypes.length; i += 1) {
    if (isType(field, BaseFieldTypes[i])) {
      return true;
    }
  }

  return false;
};

export const getOptionValueByLabel = (label: string, options: Array<Option>): string => {
  for (let i = 0; i < options.length; i += 1) {
    if (options[i].label === label) {
      return options[i].value;
    }
  }

  return '';
};

const dateRegexp = /^[0-9]{6}$/; // Expected date format: YYMMDD

export const parseMRZDate = (value: string, isDateOfBirth: boolean): string => {
  if (!dateRegexp.test(value)) {
    return '';
  }

  let year = Number(value.substring(0, 2)) + 2000;
  const month = value.substring(2, 4);
  const day = value.substring(4, 6);
  const now = new Date();

  if (isDateOfBirth && year > now.getFullYear()) {
    year -= 100;
  }

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export default {
  isType,
  getOptionValueByLabel,
  parseMRZDate,
};
