
import Vue from 'vue';
import { Field } from '@/manual-review/form';

export default Vue.extend({
  data() {
    return {
      year: '',
    };
  },

  props: {
    field: {
      type: Object as () => Field,
    },
  },

  mounted() {
    if (!this.field) {
      return;
    }

    [this.year] = String(this.field.value).split('-');
  },

  watch: {
    year(newVal) {
      const date = `${newVal}-12-31`;

      if (this.field.value === date) {
        return;
      }

      this.field.value = date;
    },
  },
});
