import { FieldType } from '@/manual-review/form';

import validate from '@/manual-review/validations';
import {
  Document,
} from './types';

const validationNumbers = [2, 3, 4, 5, 6, 7];
const verificationDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'K', '0'];

function valdiationProduct(rut: string): number {
  let validationProduct = 0;
  let j = 0;

  for (let i = rut.length - 1; i >= 0; i -= 1) {
    const currentValidationNumber = j % validationNumbers.length;

    validationProduct += Number(rut[i]) * validationNumbers[currentValidationNumber];
    j += 1;
  }

  return validationProduct;
}

function generateValidationDigit(rut: string):string {
  const validationProd = valdiationProduct(rut);
  const valModule = validationProd % 11;

  const validationIndex = 11 - valModule;

  return verificationDigits[validationIndex];
}

function isVerificationDigitValid(rut: string) {
  const rutAux = rut.split('-');

  if (rutAux.length !== 2) {
    return false;
  }

  const rutNumber = rutAux[0];
  const verificationDigit = rutAux[1];

  const generatedDigit = generateValidationDigit(rutNumber);

  return generatedDigit === verificationDigit;
}

export function validateDocumentNumber(value: string): string | boolean {
  if (!isVerificationDigitValid(value)) {
    return 'Digite un rut válido';
  }

  return true;
}

const mrzExpectedLength = 90;
const mrzRegexpPattern = /[A-Z0-9<]{90}/;

export function validateMRZ(value: string): string | boolean {
  if (value.length !== mrzExpectedLength) {
    return `Digite un MRZ válido de ${mrzExpectedLength} caracteres`;
  }

  if (!mrzRegexpPattern.test(value)) {
    return 'Digite un MRZ con el formato válido. El código MRZ sólo debe contener letras (A-Z), números (0-9) y el caracter <';
  }

  return true;
}

const doc: Document = {
  sides: {
    front: {
      fields: [
        {
          type: FieldType.Select,
          name: 'document_version',
          label: 'Versión de documento',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: '2002', value: 'cl_national-id-2002' },
            { label: '2013', value: 'cl_national-id-2013' },
          ],
          alwaysRequired: true,
        },
        {
          type: FieldType.Text,
          name: 'document_number',
          label: 'RUN',
          rules: [validate.isRequired, validateDocumentNumber],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'last_name',
          label: 'Apellidos',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'name',
          label: 'Nombres',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'nationality',
          label: 'Nacionalidad',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Select,
          name: 'gender',
          label: 'Sexo',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'M', value: 'male' },
            { label: 'F', value: 'female' },
          ],
        },
        {
          type: FieldType.Date,
          name: 'date_of_birth',
          label: 'Fecha de nacimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
          allowedDates: validate.dateOfBirth,
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'expiration_date',
          label: 'Fecha de vencimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpiration],
          allowedDates: validate.dateOfExpiration,
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'expedition_date',
          label: 'Fecha de emisión',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpedition],
          allowedDates: validate.dateOfExpedition,
          value: '',
        },
      ],
    },
    reverse: {
      fields: [
        {
          type: FieldType.Text,
          name: 'machine_readable',
          label: 'Machine readable zone',
          rules: [validate.isRequired, validateMRZ],
          value: 'LLCHL##########L##<<<<<<<<<<<<#######L#######CHL########<#<#APELLIDO<APELLIDO<<NOMBRE<NOMB',
        },
      ],
    },
  },
};

export default doc;
