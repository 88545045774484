
import Vue from 'vue';

type Item = {
  label: string,
  value: string,
}

export default Vue.extend({
  props: ['items'],

  computed: {
    filteredItems() {
      return this.items.filter((item: Item) => item.value);
    },
  },
});
