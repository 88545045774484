export const assignmentIdNotAvailable = 'ASSIGNMENT_ID_NOT_AVAILABLE';
export const submit = 'Submit';
export const mustAccept = 'You must ACCEPT the HIT before you can submit the results.';

export function isTaskAssigned(assignmentId: string): boolean {
  if (!assignmentId) {
    return false;
  }

  return assignmentId !== assignmentIdNotAvailable;
}

export function submitText(assignmentId: string): string {
  if (isTaskAssigned(assignmentId)) {
    return submit;
  }

  return mustAccept;
}

export function turkGetParam(url: string, name: string, defaultValue: string): string {
  const regexS = `[?&]${name}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(url);

  if (results == null) {
    return defaultValue;
  }

  return results[1];
}

export function getTurkAssignmentID(): string {
  return turkGetParam(window.location.href, 'assignmentId', '');
}

export default {
  getTurkAssignmentID,
  turkGetParam,
};
