import {
  TagNoMovementsButAlive, TagImageQuality,
  TagObjectsAlteringFace, TagPhysicalChanges, TagFraudImageOrVideo,
} from '@/manual-review/tag';

export default [
  TagNoMovementsButAlive,
  TagImageQuality,
  TagObjectsAlteringFace,
  TagPhysicalChanges,
  TagFraudImageOrVideo,
];
