import { FieldType } from '@/manual-review/form';
import validations from '@/manual-review/validations';
import { Document } from './types';

const documentNumberRegexp = /^[0-9]{6,10}$/;

export function validateDocumentNumber(value: string): string | boolean {
  if (!documentNumberRegexp.test(value)) {
    return 'Digite seis a diez números sin puntos';
  }

  return true;
}

const document: Document = {
  sides: {
    front: {
      fields: [
        {
          type: FieldType.Select,
          name: 'document_version',
          label: 'Versión de documento',
          rules: [validations.isRequired],
          value: '',
          options: [
            { label: 'Cédula de ciudadanía', value: 've_national-id' },
          ],
          alwaysRequired: true,
        },
        {
          type: FieldType.Text,
          name: 'document_number',
          label: 'Número de documento',
          rules: [validations.isRequired, validateDocumentNumber],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'last_name',
          label: 'Apellidos',
          rules: [validations.isRequired, validations.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'name',
          label: 'Nombres',
          rules: [validations.isRequired, validations.name],
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'date_of_birth',
          label: 'Fecha de nacimiento',
          rules: [validations.isRequired, validations.isDate, validations.dateOfBirth],
          allowedDates: validations.dateOfBirth,
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'issue_date',
          label: 'Fecha de expedición',
          rules: [validations.isRequired, validations.isDate, validations.dateOfExpedition],
          allowedDates: validations.dateOfExpedition,
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'expiration_date',
          label: 'Fecha de vencimiento',
          rules: [validations.isRequired, validations.isDate, validations.dateOfExpiration],
          allowedDates: validations.dateOfExpiration,
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'civil_status',
          label: 'Estado Civil',
          rules: [validations.isRequired],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'expedition_place',
          label: 'Oficina Expedidora',
          rules: [validations.isRequired],
          value: '',
        },
      ],
    },
    reverse: { fields: [] },
  },
};

export default document;
