import { FieldType } from '@/manual-review/form';

import validate from '@/manual-review/validations';
import {
  Document,
} from './types';

const documentNumberRegexp = /^[0-9]{6,10}$/;

export function validateDocumentNumber(value: string): string | boolean {
  if (!documentNumberRegexp.test(value)) {
    return 'Digite seis a diez números sin puntos';
  }

  return true;
}

const doc: Document = {
  sides: {
    front: {
      fields: [
        {
          type: FieldType.Select,
          name: 'document_version',
          label: 'Versión de documento',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: '2000', value: 'co_national-id-2000' },
            { label: '2020', value: 'co_national-id-2020' },
          ],
          alwaysRequired: true,
        },
        {
          type: FieldType.Text,
          name: 'document_number',
          label: 'Número',
          rules: [validate.isRequired, validateDocumentNumber],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'last_name',
          label: 'Apellidos',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'name',
          label: 'Nombres',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
      ],
    },
    reverse: {
      fields: [
        {
          type: FieldType.Date,
          name: 'date_of_birth',
          label: 'Fecha de nacimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
          allowedDates: validate.dateOfBirth,
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'birth_place',
          label: 'Lugar de nacimiento',
          rules: [validate.isRequired, validate.place],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'height',
          label: 'Estatura',
          rules: [validate.isRequired, validate.height],
          value: '',
        },
        {
          type: FieldType.Select,
          name: 'rh',
          label: 'G.S R.H',
          rules: [validate.rh],
          value: '',
          options: [
            { label: 'A+', value: 'A+' },
            { label: 'B+', value: 'B+' },
            { label: 'AB+', value: 'AB+' },
            { label: 'O+', value: 'O+' },
            { label: 'A-', value: 'A-' },
            { label: 'B-', value: 'B-' },
            { label: 'AB-', value: 'AB-' },
            { label: 'O-', value: 'O-' },
            { label: 'No visible', value: '' },
          ],
        },
        {
          type: FieldType.Select,
          name: 'gender',
          label: 'Sexo',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'M', value: 'male' },
            { label: 'F', value: 'female' },
          ],
        },
        {
          type: FieldType.Date,
          name: 'issue_date',
          label: 'Fecha de expedición',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpedition],
          allowedDates: validate.dateOfExpedition,
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'expedition_place',
          label: 'Lugar de expedicion',
          rules: [validate.isRequired, validate.place],
          value: '',
        },
      ],
    },
  },
};

export default doc;
