import validate from '@/manual-review/validations';
import { FieldType } from '@/manual-review/form';
import { Document, CountryAlpha3 } from './types';

const passportNumberMinLength = 5;
export const passportNumberMaxLength = 9;
const passportNumberRegexp = /^[A-Z0-9]+$/;

export function validatePassportNumber(value: string): string | boolean {
  if (value.length < passportNumberMinLength || value.length > passportNumberMaxLength) {
    return `Digite un número de pasaporte de entre ${passportNumberMinLength} a ${passportNumberMaxLength} caracteres`;
  }

  if (!passportNumberRegexp.test(value)) {
    return 'Digite un número de pasaporte con caracteres válidos';
  }

  return true;
}

const expectedPassportMRZLength = 88;
const passportMRZRegexp = /^[A-Z0-9<]+$/;

export function validatePassportMRZ(value: string): string | boolean {
  if (value.length !== expectedPassportMRZLength) {
    return `Digite un MRZ de ${expectedPassportMRZLength} caracteres`;
  }

  if (!passportMRZRegexp.test(value)) {
    return 'Digite un MRZ con caracteres válidos';
  }

  return true;
}

export const personalNumberMaxLength = 14;
const personalNumberRegexp = /^[A-Z0-9]+$/;

export function validatePersonalNumber(value: string): string | boolean {
  if (value === '') {
    return true; // This field is optional
  }

  if (value.length > personalNumberMaxLength) {
    return `Digite un número personal de máximo ${personalNumberMaxLength} caracteres`;
  }

  if (!personalNumberRegexp.test(value)) {
    return 'Digite un número personal con caracteres válidos';
  }

  return true;
}

const document: Document = {
  sides: {
    front: {
      fields: [
        {
          type: FieldType.Select,
          name: 'document_version',
          label: 'Versión de documento',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'Passport', value: 'passport' },
          ],
          alwaysRequired: true,
        },
        {
          type: FieldType.Text,
          name: 'last_name',
          label: 'Apellidos',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'name',
          label: 'Nombres',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Select,
          name: 'nationality',
          label: 'Nacionalidad',
          rules: [validate.isRequired, validate.name],
          value: '',
          options: CountryAlpha3,
        },
        {
          type: FieldType.Text,
          name: 'document_number',
          label: 'Nro. de Pasaporte',
          rules: [validate.isRequired, validatePassportNumber],
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'date_of_birth',
          label: 'Fecha de nacimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
          allowedDates: validate.dateOfBirth,
          value: '',
        },
        {
          type: FieldType.Select,
          name: 'gender',
          label: 'Sexo',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'M', value: 'male' },
            { label: 'F', value: 'female' },
          ],
        },
        {
          type: FieldType.Date,
          name: 'issue_date',
          label: 'Fecha de expedición',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpedition],
          allowedDates: validate.dateOfExpedition,
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'expiration_date',
          label: 'Fecha de vencimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpiration],
          allowedDates: validate.dateOfExpiration,
          value: '',
        },
        {
          type: FieldType.PassportMRZ,
          name: 'machine_readable',
          label: 'Machine-Readable Zone (TD3)',
          rules: [validate.isRequired, validatePassportMRZ],
          value: '',
          passportMRZFields: {
            issuingCountry: {
              type: FieldType.Select,
              name: 'mrz_issuing_country',
              label: 'MRZ issuing country',
              rules: [validate.isRequired],
              value: '',
              options: CountryAlpha3,
            },
            lastName: {
              type: FieldType.Text,
              name: 'mrz_last_name',
              label: 'MRZ last name',
              rules: [validate.isRequired, validate.name],
              value: '',
            },
            name: {
              type: FieldType.Text,
              name: 'mrz_name',
              label: 'MRZ name',
              rules: [validate.isRequired, validate.name],
              value: '',
            },
            passportNumber: {
              type: FieldType.Text,
              name: 'mrz_passport_number',
              label: 'MRZ passport number',
              rules: [validate.isRequired, validatePassportNumber],
              value: '',
            },
            nationality: {
              type: FieldType.Select,
              name: 'mrz_nationality',
              label: 'MRZ nationality',
              rules: [validate.isRequired],
              value: '',
              options: CountryAlpha3,
            },
            dateOfBirth: {
              type: FieldType.Date,
              name: 'mrz_date_of_birth',
              label: 'MRZ date of birth',
              rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
              allowedDates: validate.dateOfBirth,
              value: '',
            },
            gender: {
              type: FieldType.Select,
              name: 'mrz_gender',
              label: 'MRZ gender',
              rules: [validate.isRequired],
              value: '',
              options: [
                { label: 'M', value: 'M' },
                { label: 'F', value: 'F' },
              ],
            },
            expirationDate: {
              type: FieldType.Date,
              name: 'mrz_expiration_date',
              label: 'MRZ expiration date',
              rules: [validate.isRequired, validate.isDate, validate.dateOfExpiration],
              allowedDates: validate.dateOfExpiration,
              value: '',
            },
            personalNumber: {
              type: FieldType.Text,
              name: 'mrz_personal_number',
              label: 'MRZ personal number',
              rules: [validatePersonalNumber],
              value: '',
            },
          },
        },
      ],
    },
    reverse: {
      fields: [],
    },
  },
};

export default document;
