import { FieldType } from '@/manual-review/form';

import validate from '@/manual-review/validations';
import {
  Document,
} from './types';

const idRegexp = /^[A-Za-z0-9]+$/;

export function validateDocumentNumber(value: string): string | boolean {
  if (!idRegexp.test(value)) {
    return 'Digite un documento válido';
  }

  return true;
}

const doc: Document = {
  sides: {
    front: {
      fields: [
        {
          name: 'name',
          label: 'Name',
          type: FieldType.Text,
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          name: 'last_name',
          label: 'Last Name',
          type: FieldType.Text,
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          name: 'document_number',
          label: 'Document Number',
          type: FieldType.Text,
          rules: [validate.isRequired, validateDocumentNumber],
          value: '',
        },
        {
          name: 'gender',
          label: 'Gender',
          type: FieldType.Select,
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'Male', value: 'male' },
            { label: 'Female', value: 'female' },
          ],
        },
        {
          name: 'date_of_birth',
          label: 'Date Of Birth',
          type: FieldType.Date,
          rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
          allowedDates: validate.dateOfBirth,
          value: '',
        },
        {
          name: 'residence_address',
          label: 'Residence Address',
          type: FieldType.Text,
          rules: [validate.isRequired, validate.place],
          value: '',
        },
        {
          name: 'expiration_date',
          label: 'Expiration Date',
          type: FieldType.Date,
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpiration],
          allowedDates: validate.dateOfExpiration,
          value: '',
        },

      ],
    },
    reverse: {
      fields: [],
    },
  },
};

export default doc;
