import { FieldType } from '@/manual-review/form';

import validate from '@/manual-review/validations';
import {
  BackendDocumentCountryKey,
  Document,
} from './types';

const chars = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
const charsSize = 37;
const minCurpLength = 17;
const maxCurpLength = 18;
const defaultDigit = 0;
const digitDivisor = 10;

function getFactor(root:string):Array<number> {
  const factor:Array<number> = [];

  for (let i = 0; i < root.length; i += 1) {
    const chr = root[i];

    for (let j = 0; j < charsSize; j += 1) {
      if (chr === chars[j]) {
        factor[i] = j;

        break;
      }
    }
  }

  return factor;
}

function validateCurpVerificationDigit(curp: string): boolean {
  // If invalid curp, returns invalid digit
  if (curp.length !== maxCurpLength) {
    return false;
  }

  const root = curp.slice(0, minCurpLength);
  const factor = getFactor(root);

  let sum = 0;
  for (let k = 0; k < minCurpLength; k += 1) {
    sum += (factor[k]) * (maxCurpLength - k);
  }

  let digit = (digitDivisor - (sum % digitDivisor));
  if (digit === digitDivisor) {
    digit = defaultDigit;
  }

  return digit === Number(curp[maxCurpLength - 1]);
}

export function validateDocumentNumber(value: string): string | boolean {
  if (!validateCurpVerificationDigit(value)) {
    return 'Digite un curp válido';
  }

  return true;
}

const idRegexp = /^[A-Z0-9]{18}$/;

export function validateVoterNumber(value: string): string | boolean {
  if (!idRegexp.test(value)) {
    return 'Digite un id válido de 18 caracteres';
  }

  return true;
}

const doc: Document = {
  sides: {
    front: {
      fields: [
        {
          type: FieldType.Select,
          name: 'document_version',
          label: 'Versión de documento',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'INE-E', value: 'mx_ine-e' },
            { label: 'INE-F', value: 'mx_ine-f' },
            { label: 'INE-G', value: 'mx_ine-g' },
            { label: 'IFE-C', value: 'mx_ife-c' },
            { label: 'IFE-D', value: 'mx_ife-d' },
          ],
          alwaysRequired: true,
        },
        {
          type: FieldType.Text,
          name: 'last_name',
          label: 'Apellidos (Primera y segunda línea)',
          rules: [validate.isRequired, validate.namesWithSpecialCharacters],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'name',
          label: 'Nombres (Tercera línea)',
          rules: [validate.isRequired, validate.namesWithSpecialCharacters],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'residence_address',
          label: 'Domicilio',
          rules: [validate.isRequired, validate.place],
          value: '',
        },
        {
          type: FieldType.Select,
          name: 'gender',
          label: 'Sexo',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'H', value: 'male' },
            { label: 'M', value: 'female' },
          ],
        },
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.state`,
          label: 'Estado',
          rules: [],
          value: '',
        },
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.municipality`,
          label: 'Municipio',
          rules: [],
          value: '',
        },
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.section`,
          label: 'Sección',
          rules: [validate.isRequired, validate.numberINEData],
          value: '',
        },
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.locality`,
          label: 'Localidad',
          rules: [],
          value: '',
        },
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.elector_key`,
          label: 'Clave de elector',
          rules: [validate.isRequired, validateVoterNumber],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'document_number',
          label: 'CURP',
          rules: [validate.isRequired, validateDocumentNumber],
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'date_of_birth',
          label: 'Fecha de nacimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
          allowedDates: validate.dateOfBirth,
          value: '',
        },
        {
          type: FieldType.Year,
          name: 'registration_date',
          label: 'Año de registro',
          rules: [validate.isRequired, validate.isYear, validate.dateOfRegistration],
          allowedDates: validate.dateOfRegistration,
          value: '',
        },
        {
          type: FieldType.Year,
          name: 'expiration_date',
          label: 'Vigencia',
          rules: [validate.isRequired, validate.isYear, validate.dateOfExpiration],
          allowedDates: validate.dateOfExpiration,
          value: '',
        },
      ],
    },
    reverse: {
      fields: [
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.cic`,
          label: 'CIC',
          rules: [validate.number],
          value: '',
        },
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.ocr`,
          label: 'OCR',
          rules: [validate.isRequired, validate.number],
          value: '',
        },
      ],
    },
  },
};

export default doc;
