import { FieldType } from '@/manual-review/form';

import validate from '@/manual-review/validations';
import { Document } from './types';

const mrzExpectedLength = 90;
const mrzRegexpPattern = /[A-Z0-9<]{90}/;

export function validateMRZ(value: string): string | boolean {
  if (value.length !== mrzExpectedLength) {
    return `Digite un MRZ de ${mrzExpectedLength} caracteres`;
  }

  if (!mrzRegexpPattern.test(value)) {
    return 'Digite un MRZ con caracteres válidos';
  }

  return true;
}

const doc: Document = {
  sides: {
    front: {
      fields: [
        {
          type: FieldType.Text,
          name: 'foreign_id',
          label: 'RUMV',
          rules: [validate.isRequired],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'last_name',
          label: 'Apellidos',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'name',
          label: 'Nombres',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'nationality',
          label: 'Nacionalidad',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Select,
          name: 'gender',
          label: 'Sexo',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'M', value: 'male' },
            { label: 'F', value: 'female' },
          ],
        },
        {
          type: FieldType.Text,
          name: 'document_number',
          label: 'Número de documento',
          rules: [validate.isRequired],
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'date_of_birth',
          label: 'Fecha de nacimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
          allowedDates: validate.dateOfBirth,
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'issue_date',
          label: 'Fecha de expedición',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpedition],
          allowedDates: validate.dateOfExpedition,
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'expedition_place',
          label: 'Lugar de expedición',
          rules: [validate.isRequired, validate.place],
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'expiration_date',
          label: 'Fecha de vencimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpiration],
          allowedDates: validate.dateOfExpiration,
          value: '',
        },
      ],
    },
    reverse: {
      fields: [
        {
          type: FieldType.Text,
          name: 'machine_readable',
          label: 'Machine readable zone',
          rules: [validate.isRequired, validateMRZ],
          value: 'IPCOL#######<<9<<<<<<<<<<<<<<<######5#######8###########<<<3APELLI<#APELLIDO<<NOMB<<<<<<<<',
        },
      ],
    },
  },
};

export default doc;
