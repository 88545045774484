import { FieldType } from '@/manual-review/form';

import validate from '@/manual-review/validations';
import {
  Document,
} from './types';

type digitsTable = Array<number>

const cpfFirstDigitTable = [10, 9, 8, 7, 6, 5, 4, 3, 2];
const cpfSecondDigitTable = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
const cpfLength = 11;
const documentNumberRegexp = /^[0-9]{11}$/;

function sumDigit(s: string, table: digitsTable): number {
  let sum = 0;

  for (let i = 0; i < table.length; i += 1) {
    const v = table[i];
    const d = Number(s[i]);

    sum += v * d;
  }

  return sum;
}

function isCPFValid(cpf: string): boolean {
  if (!documentNumberRegexp.test(cpf)) {
    return false;
  }

  const firstPart = cpf.substring(0, cpfFirstDigitTable.length);
  let sum = sumDigit(firstPart, cpfFirstDigitTable);

  const firstResultMod = sum % cpfLength;
  let firstDigit = 0;

  if (firstResultMod >= 2) {
    firstDigit = cpfLength - firstResultMod;
  }

  const secondPart = firstPart + String(firstDigit);
  sum = sumDigit(secondPart, cpfSecondDigitTable);

  const secondResultMod = sum % cpfLength;
  let secondDigit = 0;

  if (secondResultMod >= 2) {
    secondDigit = cpfLength - secondResultMod;
  }

  return `${firstPart}${String(firstDigit)}${String(secondDigit)}` === cpf;
}

export function validateDocumentNumber(value: string): string | boolean {
  if (!isCPFValid(value)) {
    return 'Digite once números sin puntos ni guiones';
  }

  return true;
}

const doc: Document = {
  sides: {
    front: {
      fields: [
        {
          type: FieldType.Select,
          name: 'document_version',
          label: 'Versión de documento',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'CNH', value: 'br_driver-license' },
          ],
          alwaysRequired: true,
        },
        {
          type: FieldType.Text,
          name: 'name',
          label: 'Nome',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'document_number',
          label: 'CPF',
          rules: [validate.isRequired, validateDocumentNumber],
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'date_of_birth',
          label: 'Data nascimento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
          allowedDates: validate.dateOfBirth,
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'expiration_date',
          label: 'Validade',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpiration],
          allowedDates: validate.dateOfExpiration,
          value: '',
        },
      ],
    },
    reverse: {
      fields: [],
    },
  },
};

export default doc;
