/* eslint-disable camelcase */

import {
  Option,
  Field,
  FieldType,
} from './form';

import validate, { ValidationType } from './validations';

export enum TaskStatus {
  Pending = 'pending',
  Valid = 'valid',
  Invalid = 'invalid',
}

export type Task = {
  name: string,
  description: string,
  task_status?: TaskStatus,
  options?: Array<Option>,
  value?: string,
  type?: FieldType,
}

export type MapTask = {
  [name in ValidationType]?: Task
}

export const mapTasks: MapTask = {
  [ValidationType.PhotocopyValidation]: {
    name: ValidationType.PhotocopyValidation,
    description: '¿Cualquiera de las imágenes es una fotocopia?',
    options: [
      {
        label: 'Sí, una o más de las imágenes es fotocopia',
        value: `${ValidationType.PhotocopyValidation}:${TaskStatus.Invalid}`,
      },
      {
        label: 'No, ninguna es una fotocopia',
        value: `${ValidationType.PhotocopyValidation}:${TaskStatus.Valid}`,
      },
    ],
  },
  [ValidationType.PhotoOfPhotoValidation]: {
    name: ValidationType.PhotoOfPhotoValidation,
    description: '¿Cualquiera de las imágenes es una foto de foto?',
    options: [
      {
        label: 'Sí, una o más de las imágenes es foto de foto',
        value: `${ValidationType.PhotoOfPhotoValidation}:${TaskStatus.Invalid}`,
      },
      {
        label: 'No, ninguna es una foto de foto',
        value: `${ValidationType.PhotoOfPhotoValidation}:${TaskStatus.Valid}`,
      },
    ],
  },
  [ValidationType.FakeTextValidation]: {
    name: ValidationType.FakeTextValidation,
    description: '¿El texto de cualquiera de las imágenes está modificado?',
    options: [
      {
        label: 'Sí, el texto está modificado',
        value: `${ValidationType.FakeTextValidation}:${TaskStatus.Invalid}`,
      },
      {
        label: 'No, el texto NO está modificado',
        value: `${ValidationType.FakeTextValidation}:${TaskStatus.Valid}`,
      },
    ],
  },
  [ValidationType.FrontImageAlterations]: {
    name: ValidationType.FrontImageAlterations,
    description: '¿El texto del frente del documento está modificado?',
    options: [
      {
        label: 'Sí, el texto está modificado',
        value: `${ValidationType.FrontImageAlterations}:${TaskStatus.Invalid}`,
      },
      {
        label: 'No, el texto NO está modificado',
        value: `${ValidationType.FrontImageAlterations}:${TaskStatus.Valid}`,
      },
    ],
  },
  [ValidationType.ReverseImageAlterations]: {
    name: ValidationType.ReverseImageAlterations,
    description: '¿El texto del reverso del documento está modificado?',
    options: [
      {
        label: 'Sí, el texto está modificado',
        value: `${ValidationType.ReverseImageAlterations}:${TaskStatus.Invalid}`,
      },
      {
        label: 'No, el texto NO está modificado',
        value: `${ValidationType.ReverseImageAlterations}:${TaskStatus.Valid}`,
      },
    ],
  },
  [ValidationType.FakePicture]: {
    name: ValidationType.FakePicture,
    description: '¿La cara de la persona es real en todas las fotos o videos?',
    options: [
      {
        label: 'Si, todas son fotos reales',
        value: `${ValidationType.FakePicture}:${TaskStatus.Valid}`,
      },
      {
        label: 'No, una o mas caras son falsas o son foto de foto o video de video',
        value: `${ValidationType.FakePicture}:${TaskStatus.Invalid}`,
      },
    ],
  },
  [ValidationType.FaceMatch]: {
    name: ValidationType.FaceMatch,
    description: '¿La persona es la misma en todas las fotos o videos?',
    options: [
      {
        label: 'Sí, es la misma persona',
        value: `${ValidationType.FaceMatch}:${TaskStatus.Valid}`,
      },
      {
        label: 'No, son diferentes',
        value: `${ValidationType.FaceMatch}:${TaskStatus.Invalid}`,
      },
    ],
  },
  [ValidationType.Liveness]: {
    name: ValidationType.Liveness,
    description: '¿La persona de acuerdo a la configuración del cliente está viva?',
    options: [
      {
        label: 'Sí, la persona está viva',
        value: `${ValidationType.Liveness}:${TaskStatus.Valid}`,
      },
      {
        label: 'No, la persona no está viva',
        value: `${ValidationType.Liveness}:${TaskStatus.Invalid}`,
      },
    ],
  },
  [ValidationType.PassiveLiveness]: {
    name: ValidationType.PassiveLiveness,
    description: '¿Hubo fraude en el video?',
    options: [
      {
        label: 'Sí',
        value: `${ValidationType.PassiveLiveness}:${TaskStatus.Invalid}`,
      },
      {
        label: 'No',
        value: `${ValidationType.PassiveLiveness}:${TaskStatus.Valid}`,
      },
    ],
  },
  [ValidationType.PassiveLivenessOptions]: {
    name: ValidationType.PassiveLivenessOptions,
    description: '¿Qué tipo de fraude hubo?',
    type: FieldType.MultipleOptions,
    options: [
      {
        label: 'Foto física (impresa, fotocopia, documento, etc)',
        value: `${ValidationType.PassiveLivenessOptions}:print_attack`,
      },
      {
        label: 'Foto/video en pantalla (fotocopia, documento, etc)',
        value: `${ValidationType.PassiveLivenessOptions}:replay_attack`,
      },
      {
        label: 'Usuario con máscara',
        value: `${ValidationType.PassiveLivenessOptions}:3d_mask`,
      },
      {
        label: 'Rostro/Video generado por computador',
        value: `${ValidationType.PassiveLivenessOptions}:deepfake`,
      },

    ],
  },
};

const defaultTask: Task = {
  name: 'default',
  description: '',
  options: [
    {
      label: 'Valid',
      value: `${ValidationType.FakePicture}:${TaskStatus.Valid}`,
    },
    {
      label: 'Invalid',
      value: `${ValidationType.FakePicture}:${TaskStatus.Invalid}`,
    },
  ],
};

export function getTask(task: Task): Task {
  const taskStatus = task.task_status === TaskStatus.Pending ? '' : `${task.name}:${task.task_status}`;
  const defTask = {
    ...defaultTask,
    ...task,
    description: task.name,
    value: taskStatus,
  };

  const mapTask = mapTasks[task.name as ValidationType];
  if (!mapTask) {
    return defTask;
  }

  return { ...defTask, ...mapTask };
}

export function getTasks(tasks: Array<Task>): Array<Field> {
  const fields: Array<Field> = [];

  for (let i = 0; i < tasks.length; i += 1) {
    const task = tasks[i];
    const newTask = getTask(task);
    const fieldType = newTask.type ? newTask.type : FieldType.Select;

    const field:Field = {
      type: fieldType,
      label: newTask.description,
      value: newTask.value || '' || [],
      rules: [validate.isRequired],
      options: newTask.options,
      name: 'task_status',
      key: newTask.name,
    };

    fields.push(field);
  }

  return fields;
}

export default {
  getTasks,
};
