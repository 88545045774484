import { Field, FieldType } from './form';

export type Tag = {
    label: string,
    value: string
}

export const TagNoMovementsButAlive = {
  label: 'No realiza el movimiento requerido pero está vivo',
  value: 'not_required_move_but_alive',
};

export const TagImageQuality = {
  label: 'Calidad de la imagen',
  value: 'image_quality',
};

export const TagNotOriginalDocumentPhoto = {
  label: 'Foto de documento no original',
  value: 'not_original_document_photo',
};

export const TagDocumentFraud = {
  label: 'Fraude de documento',
  value: 'document_fraud',
};

export const TagUserErrors = {
  label: 'Errores del usuario',
  value: 'user_errors',
};

export const TagOcrErrors = {
  label: 'Errores del ocr',
  value: 'ocr_errors',
};

export const TagObjectsAlteringFace = {
  label: 'Objetos que alteran el rostro',
  value: 'objects_altering_face',
};

export const TagPhysicalChanges = {
  label: 'Cambios en el físico de la persona',
  value: 'physical_changes',
};

export const TagFraudImageOrVideo = {
  label: 'Es una imagen o video fraude',
  value: 'fraud_image_or_video',
};

export function getTagsField(tags: Array<Tag>): Field {
  return {
    type: FieldType.MultipleOptions,
    label: '¿Por qué motivo el usuario no pasó la validación?',
    value: [],
    rules: [],
    options: tags,
    name: 'tags',
    key: 'Tags',
  };
}
