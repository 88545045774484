import { FieldType } from '@/manual-review/form';

import validate from '@/manual-review/validations';
import {
  BackendDocumentCountryKey,
  Document,
} from './types';

const postalCodeRegex = /^[0-9]{5}$|^$/;

export function validatePostalCode(value: string): string | boolean {
  if (!postalCodeRegex.test(value)) {
    return 'Digite 5 dígitos';
  }

  return true;
}

const doc: Document = {
  sides: {
    front: {
      fields: [
        {
          type: FieldType.Select,
          name: 'document_version',
          label: 'Versión de documento',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'CFE', value: 'mx_invoice-cfe' },
            { label: 'Telmex', value: 'mx_invoice-telmex' },
            { label: 'Totalplay', value: 'mx_invoice-totalplay' },
          ],
          alwaysRequired: true,
        },
        {
          type: FieldType.Text,
          name: 'name',
          label: 'Nombre',
          rules: [validate.isRequired],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'street',
          label: 'Calle',
          rules: [validate.isRequired],
          value: '',
        },
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.exterior_number`,
          label: 'Número exterior (ejm. 125, 572-A, LT 23, MZ 15)',
          rules: [],
          value: '',
        },
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.interior_number`,
          label: 'Número interior (ejm. 2, Local C,L-5)',
          rules: [],
          value: '',
        },
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.neighborhood`,
          label: 'Colonia',
          rules: [validate.isRequired],
          value: '',
        },
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.municipality_name`,
          label: 'Municipio',
          rules: [validate.isRequired],
          value: '',
        },
        {
          type: FieldType.Text,
          name: `${BackendDocumentCountryKey.Mexico}.state_name`,
          label: 'Estado',
          rules: [validate.isRequired],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'postal_code',
          label: 'Código postal (5 dígitos)',
          rules: [validatePostalCode],
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'pay_limit_date',
          label: 'Fecha límite de pago (YYYY-MM-DD ejm. 2022-05-18)',
          rules: [validate.isDate],
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'issue_date',
          label: 'Fecha de emisión (YYYY-MM-DD ejm. 2022-05-18)',
          rules: [validate.isDate],
          value: '',
        },
      ],
    },
    reverse: {
      fields: [],
    },
  },
};

export default doc;
