import {
  TagDocumentFraud, TagImageQuality, TagNotOriginalDocumentPhoto, TagOcrErrors, TagUserErrors,
} from '@/manual-review/tag';

export default [
  TagImageQuality,
  TagNotOriginalDocumentPhoto,
  TagDocumentFraud,
  TagUserErrors,
  TagOcrErrors,
];
