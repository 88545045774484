import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import DocumentsValidation from '@/views/DocumentsValidation.vue';
import FaceValidation from '@/views/FaceValidation.vue';

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: '/manual-review-document-validation/:questionJSON',
    name: 'DocumentsValidation',
    component: DocumentsValidation,
    props: (route) => ({ question: JSON.parse(route.params.questionJSON) }),
  },
  {
    path: '/manual-review-face-validation/:questionJSON',
    name: 'FaceValidation',
    component: FaceValidation,
    props: (route) => ({ question: JSON.parse(route.params.questionJSON) }),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
