import { FieldType } from '@/manual-review/form';

import validate from '@/manual-review/validations';
import {
  Document,
} from './types';

const idRegexp = /^[0-9]{15}$/;

export function validateDocumentNumber(value: string): string | boolean {
  if (!idRegexp.test(value)) {
    return 'Digite un pep válido de 15 dígitos';
  }

  return true;
}

const doc: Document = {
  sides: {
    front: {
      fields: [
        {
          type: FieldType.Select,
          name: 'document_version',
          label: 'Versión de documento',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'PEP', value: 'co_pep' },
          ],
          alwaysRequired: true,
        },
        {
          type: FieldType.Text,
          name: 'document_number',
          label: 'Número de documento',
          rules: [validate.isRequired, validateDocumentNumber],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'last_name',
          label: 'Apellidos',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Text,
          name: 'name',
          label: 'Nombres',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Select,
          name: 'gender',
          label: 'Sexo',
          rules: [validate.isRequired],
          value: '',
          options: [
            { label: 'M', value: 'male' },
            { label: 'F', value: 'female' },
          ],
        },
        {
          type: FieldType.Text,
          name: 'nationality',
          label: 'Nacionalidad',
          rules: [validate.isRequired, validate.name],
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'date_of_birth',
          label: 'Fecha de nacimiento',
          rules: [validate.isRequired, validate.isDate, validate.dateOfBirth],
          allowedDates: validate.dateOfBirth,
          value: '',
        },
        {
          type: FieldType.Date,
          name: 'expedition_date',
          label: 'Fecha de expedición',
          rules: [validate.isRequired, validate.isDate, validate.dateOfExpedition],
          allowedDates: validate.dateOfExpedition,
          value: '',
        },
      ],
    },
    reverse: {
      fields: [],
    },
  },
};

export default doc;
