
import Vue from 'vue';
import BaseInput from '@/components/BaseInput.vue';
import { Field } from '@/manual-review/form';

function completeFieldLength(value: string, length: number): string {
  return value.padEnd(length, '<').substring(0, length);
}

function parseNameField(field: string): string {
  return field.toUpperCase().replace(/ +/g, ' ').trim().replace(/ /g, '<');
}

const expectedDateParts = 3;

function parseDateField(field: string): string {
  const dateParts = field.split('-');

  if (dateParts.length !== expectedDateParts) {
    return '<<<<<<';
  }

  return dateParts[0].slice(-2) + dateParts[1] + dateParts[2]; // Format: YYMMDD
}

// Algorithm used to calculate the check digit: http://www.highprogrammer.com/alan/numbers/mrp.html#checkdigit
function calculateCheckDigit(value: string): number {
  let digit = 0;
  const factors = [7, 3, 1];

  for (let i = 0; i < value.length; i += 1) {
    let charCode = value.charCodeAt(i);

    if (charCode === 60) charCode = 0; // <
    if (charCode >= 65) charCode -= 55; // A - Z
    if (charCode >= 48) charCode -= 48; // 0 - 9

    charCode *= factors[i % 3];
    digit += charCode;
  }

  digit %= 10;

  return digit;
}

export default Vue.extend({
  components: { BaseInput },

  props: {
    field: {
      type: Object as () => Field,
    },
  },

  computed: {
    // MRZ structure: https://en.wikipedia.org/wiki/Machine-readable_passport#Official_travel_documents
    getTd1MRZ(): string {
      if (!this.field || !this.field.td1MRZFields) {
        return '';
      }

      const {
        issuingCountry, documentNumber, nationality, dateOfBirth, gender,
        expirationDate, lastName, name,
      } = this.field.td1MRZFields;

      // First row
      let mrz = 'I<';
      // Issuing country or organization, positions 3-5, length 3
      mrz += completeFieldLength(String(issuingCountry.value), 3);
      // Document number, positions 6-14, length 9
      let documentNumberWithCheckDigit = completeFieldLength(String(documentNumber.value), 9);
      // Check digit over digits 6–14, position 15, length 1
      documentNumberWithCheckDigit += calculateCheckDigit(documentNumberWithCheckDigit);
      mrz += documentNumberWithCheckDigit;
      // Complete first row
      mrz = completeFieldLength(mrz, 30);

      // Second row
      // Date of birth (YYMMDD), positions 1-6, length 6
      const parsedDateOfBirth = parseDateField(String(dateOfBirth.value));
      // Check digit over digits 1–6, position 7, length 1
      const dateOfBirthWithCheckDigit = parsedDateOfBirth + calculateCheckDigit(parsedDateOfBirth);
      mrz += dateOfBirthWithCheckDigit;

      // Gender, positition 8, length 1
      mrz += completeFieldLength(String(gender.value), 1).toUpperCase();

      // Expiration date (YYMMDD), positions 9-14, length 6
      const parsedExpirationDate = parseDateField(String(expirationDate.value));
      // Check digit over digits 9–14, position 15, length 1
      const expirationDateWithCheckDigit = (
        parsedExpirationDate
        + calculateCheckDigit(parsedExpirationDate)
      );
      mrz += expirationDateWithCheckDigit;

      // Nationality, positions 16-18, length 3
      mrz += completeFieldLength(String(nationality.value), 3);

      // Complete row
      mrz = completeFieldLength(mrz, 59);

      // Check digit over digits 6–30 (upper line), 1–7, 9–15, 19–29 (middle line)
      mrz += calculateCheckDigit(
        documentNumberWithCheckDigit
        + dateOfBirthWithCheckDigit
        + expirationDateWithCheckDigit,
      );

      // Third row
      const fullName = `${parseNameField(String(lastName.value))}<<${parseNameField(String(name.value))}`;
      mrz += completeFieldLength(fullName, 30);

      return mrz;
    },
  },
});
