import { FieldType, Field } from '@/manual-review/form';
import {
  BackendDocument, Country, DocumentType, DocumentVersion,
} from '@/documents-validation/types';
import validate from '@/manual-review/validations';

const activateOptionalFieldsClientIDs: Array<string> = [
  'TCI1224d81f1152b9d10e5cb1dd5863e846', // fundaciongruposocial
  'TCId0161bfa4b70349871930c1527b349ba', // grupofundacionsocial
  'TCI42540fa3851c13443c25776ff5fd7eac', // testingfgs (produccion)
  'TCId9ee56fe38c4e8fef790aa50131ad1ff', // fgs-aliados
  'b6tcorivshibaguog4m4v9ttf', // truorateam
];

export function isOptionalFieldsActive(document: BackendDocument): boolean {
  if (document.country !== Country.CO || document.document_type !== DocumentType.NationalID
      || document.document_version === DocumentVersion.CONationalID2020) {
    return false;
  }

  if (document.client_id === undefined) {
    return false;
  }

  return activateOptionalFieldsClientIDs.includes(document.client_id);
}

const productionDataRegexp = /^[A-Z]{1}-[0-9]{7}-[0-9]{8}-[A-Z]{1}-[0-9]{6,10}-[0-9]{8}$/;

export function validateProductionData(value: string): string | boolean {
  if (value === '') {
    return true;
  }

  if (!productionDataRegexp.test(value)) {
    return 'Digite una información de producción con formato válido (e.g. A-1234567-12345678-A-1234567890-12345678)';
  }

  return true;
}

export const coReverseOptionalFields: Array<Field> = [
  {
    type: FieldType.Text,
    name: 'production_data',
    label: 'Información de producción',
    rules: [validateProductionData],
    value: '',
  },
  {
    type: FieldType.Select,
    name: 'national_registrar',
    label: 'Registrador nacional',
    rules: [validate.isRequired],
    value: '',
    options: [
      { label: 'ALEXANDER VEGA ROCHA (01/01/2020 - Presente)', value: 'ALEXANDER VEGA ROCHA' },
      { label: 'JUAN CARLOS GALINDO VACHA (01/01/2007 - 31/12/2008) (01/01/2015 - 31/12/2019)', value: 'JUAN CARLOS GALINDO VACHA' },
      { label: 'CARLOS ARIEL SANCHEZ (06/12/2007 - 31/12/2015)', value: 'CARLOS ARIEL SANCHEZ' },
      { label: 'ALMABEATRIZ RENGIFO LOPEZ (01/01/2002 - 31/12/2006)', value: 'ALMABEATRIZ RENGIFO LOPEZ' },
      { label: 'IVAN DUQUE ESCOBAR (01/08/1999 - 31/12/2002)', value: 'IVAN DUQUE ESCOBAR' },
    ],
  },
];
