
import Vue, { PropType } from 'vue';
import FormInput from '@/components/FormInput.vue';
import { getDocument } from '@/documents-validation/document';
import { getTasks, TaskStatus } from '@/manual-review/tasks';
import { getTagsField } from '@/manual-review/tag';
import { getTurkAssignmentID, isTaskAssigned } from '@/manual-review/assignment';
import {
  Document, DocumentQuestion, DocumentSide, Sides,
} from '@/documents-validation/types';
import tags from '@/documents-validation/tags';
import { Field, Option } from '@/manual-review/form';
import ValidationDetails from '@/components/ValidationDetails.vue';

type DocumentValidation = {
  document: Document;
  tasks: Array<Field>;
  tagsField: Field;
  assignmentId: string;
};

interface Form {
  validate?(): boolean;
}

const qualityFields = ['front_quality_features', 'reverse_quality_features'];

export default Vue.extend({
  data() {
    const data: DocumentValidation = {
      document: {
        sides: {
          front: {
            title: 'Frente',
            fields: [],
          },
          reverse: {
            title: 'Reverso',
            fields: [],
          },
        },
      },
      tasks: [],
      tagsField: getTagsField(tags),
      assignmentId: '',
    };

    return data;
  },

  components: {
    FormInput,
    ValidationDetails,
  },

  props: {
    question: {
      type: Object as PropType<DocumentQuestion>,
    },
  },

  computed: {
    sides(): Array<DocumentSide> {
      const { sides } = this.document;

      return Object.keys(sides)
        .map((key) => sides[key as keyof Sides])
        .filter((side) => side.image);
    },

    descriptionItems(): Array<Option> {
      const doc = this.question.document;

      if (!doc) return [];

      const command = doc.validation_id
        && doc.client_id
        && `/validationdata@BettyABot ${doc.validation_id} ${doc.client_id}`;

      const username = doc.username || '';
      const client = username.split('-')[0].substr(0, 24);

      return [
        { label: 'Client', value: client },
        { label: 'Country', value: doc.country },
        { label: 'Document type', value: doc.document_type },
        {
          label: 'Command',
          value: command || '',
        },
      ];
    },

    isDocumentInvalid(): boolean {
      for (let i = 0; i < this.tasks.length; i += 1) {
        const task = this.tasks[i];
        const stringValue = String(task.value);

        if (stringValue.includes(TaskStatus.Invalid)) {
          return true;
        }
      }

      return false;
    },
  },

  mounted() {
    this.document = getDocument(this.question);
    this.assignmentId = getTurkAssignmentID();

    if (!this.question.tasks) {
      return;
    }

    this.tasks = getTasks(this.question.tasks);
  },

  methods: {
    isValid(): boolean {
      const form = this.$refs.form as Form;
      if (!form.validate) {
        return false;
      }

      return form.validate();
    },

    onSubmit() {
      if (!this.isValid()) {
        return;
      }

      const formVue = this.$refs.form as Vue;
      const formElement = formVue.$el as HTMLFormElement;

      formElement.submit();
    },

    switchImages() {
      if (this.sides.length !== 2) {
        return;
      }

      const imageAux = this.sides[0].image;
      this.sides[0].image = this.sides[1].image;
      this.sides[1].image = imageAux;
    },

    isTaskAssigned(): boolean {
      return isTaskAssigned(this.assignmentId);
    },

    submitText() {
      if (isTaskAssigned(this.assignmentId)) {
        return 'Submit';
      }

      return 'You must ACCEPT the HIT before you can submit the results.';
    },

    fiterByQuality(fields: Array<Field>): Array<Field> {
      let cleanedFields: Array<Field> = fields;

      if (this.isDocumentInvalid) {
        cleanedFields = fields.filter((field) => {
          if (qualityFields.includes(field.name)) {
            return true;
          }

          return field.alwaysRequired;
        });
      }

      const [firstField] = cleanedFields;

      if (!firstField) {
        return cleanedFields;
      }

      if (!qualityFields.includes(firstField.name)) {
        return cleanedFields;
      }

      if (!firstField.value) {
        return cleanedFields;
      }

      return [firstField];
    },
  },
});
