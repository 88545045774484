
/* eslint-disable */
import Vue, { PropType } from 'vue';
import { Action } from '@/face-validation/types';

export default Vue.extend({
  props: {
    actions: {
      type: Array as () => PropType<Array<Action>>,
    },
  },
});
