export enum FieldType {
  Text,
  Select,
  MultipleOptions,
  Date,
  Year,
  PassportMRZ,
  Td1MRZ
}

export const BaseFieldTypes: Array<FieldType> = [
  FieldType.Text,
  FieldType.Select,
  FieldType.MultipleOptions,
  FieldType.Date,
  FieldType.Year,
];

export type Rule = {
  (value: string): string | boolean;
}

export type Option = {
  value: string,
  label: string,
}

export type BaseField = {
  key?: string,
  label: string,
  value: string | number | string[],
  rules: Array<Rule>,
  type: FieldType,
  name: string,
  options?: Array<Option>,
  allowedDates?: { (date: string): boolean },
  alwaysRequired?: boolean,
}

export type PassportMRZSubfields = {
  issuingCountry: BaseField,
  lastName: BaseField,
  name: BaseField,
  passportNumber: BaseField,
  nationality: BaseField,
  dateOfBirth: BaseField,
  gender: BaseField,
  expirationDate: BaseField,
  personalNumber: BaseField,
}

export type Td1MRZSubfields = {
  issuingCountry: BaseField,
  documentNumber: BaseField,
  dateOfBirth: BaseField,
  gender: BaseField,
  expirationDate: BaseField,
  nationality: BaseField,
  lastName: BaseField,
  name: BaseField,
}

export type Subfields = {
  passportMRZFields?: PassportMRZSubfields,
  td1MRZFields?: Td1MRZSubfields,
}

export type Field = BaseField & Subfields
