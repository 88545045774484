export enum ValidationType {
  Curp = 'curp-validation',
  MRZ = 'mrz-validation',
  FormatMRZTypeIII = 'mrz-format-validation',
  DateOfBirth = 'date-of-birth-validation',
  DigitalValidationGovernmentDatabaseCOCertificateCedula = 'government-database-digital-validation-certificate-cedula',
  DigitalValidationGovernmentDatabaseCOCertificateForeignID = 'government-database-digital-validation-certificate-foreign-id',
  DigitalValidationGovernmentDatabaseCOCertificatePEP = 'government-database-digital-validation-certificate-pep',
  DigitalValidationGovernmentDatabaseMXINE = 'government-database-digital-validation-ine',
  DigitalValidationGovernmentDatabaseMXRenapo = 'government-database-digital-validation-renapo',
  DigitalValidationGovernmentDatabasePEReniec = 'government-database-digital-validation-reniec',
  ExpirationDate = 'expiration-date-validation',
  ProductionData = 'data-validation-document-number',
  NationalRegistrar = 'data-validation-national-registrar',
  FrontImageAlterations = 'front-image-alterations-validation',
  ReverseImageAlterations = 'reverse-image-alterations-validation',
  FakePicture = 'fake-picture-validation',
  PhotocopyValidation = 'photocopy-validation',
  PhotoOfPhotoValidation = 'photo-of-photo-validation',
  FakeTextValidation = 'fake-text-validation',
  ManualValidation = 'manual-validation',
  DigitalValidationGovernmentDatabaseBR = 'government-database-digital-validation-brazil',
  FaceMatch = 'face-match-validation',
  Liveness = 'liveness-validation',
  PassiveLiveness = 'passive-liveness-validation',
  PassiveLivenessOptions = 'passive-liveness-validation-options',
}

const namesRegexp = /^[a-zA-ZñÑ\s]+$|^$/;

function name(value: string): string | boolean {
  if (!namesRegexp.test(value)) {
    return 'Digite solamente caracteres alfabéticos sin tilde';
  }

  return true;
}

const namesWithSpecialCharactersRegexp = /^[a-zA-ZñÑ/.'\-\s]+$|^$/;

function namesWithSpecialCharacters(value: string): string | boolean {
  if (!namesWithSpecialCharactersRegexp.test(value)) {
    return 'Digite solamente caracteres alfabéticos sin tilde, o los caracteres especiales: / - . \'';
  }

  return true;
}

const placeRegexp = /^[a-zA-ZÁ-ü0-9ñÑ().,#'/\-\s]+$|^$/;
function place(value: string): string | boolean {
  if (!placeRegexp.test(value)) {
    return 'Digite caracteres alfanuméricos';
  }

  return true;
}

const numberRegexp = /^[0-9]+$|^$/;
function number(value: string): string | boolean {
  if (!numberRegexp.test(value)) {
    return 'Digite un número válido';
  }

  return true;
}

const numberINEDataRegexp = /^[1-9]([0-9]{0,3}$)/;
function numberINEData(value: string): string | boolean {
  if (!numberINEDataRegexp.test(value)) {
    return 'Digite un número válido';
  }

  return true;
}

const countryRegexp = /^[a-z]{2}$|^$/;
function country(value: string): string | boolean {
  if (!countryRegexp.test(value)) {
    return 'Digite un país válido de 2 letras. Ejemplo: co';
  }

  return true;
}

const isDateRegexp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$|^$/;
function isDate(value: string): string | boolean {
  if (!isDateRegexp.test(value)) {
    return 'Digite una fecha válida';
  }

  return true;
}

const isYearReg = /^[12]{1}[09]{1}[0-9]{2}$|^$/;
function isYear(value: string): string | boolean {
  if (!isYearReg.test(value)) {
    return 'Digite un año válido';
  }

  return true;
}

const isRequiredRegexp = /^.+$/;
function isRequired(value: string): string | boolean {
  if (!isRequiredRegexp.test(value)) {
    return 'Campo requerido';
  }

  return true;
}

const aHundredYearsInMiliseconds = 3153600000000;
const now = new Date();

function dateOfBirth(dateStr: string): boolean {
  const date = new Date(dateStr);
  const aHundredYearsAgo = new Date(now.getTime() - aHundredYearsInMiliseconds);

  return date < now && date > aHundredYearsAgo;
}

function dateOfExpedition(dateStr: string): boolean {
  const date = new Date(dateStr);
  const aHundredYearsAgo = new Date(now.getTime() - aHundredYearsInMiliseconds);

  return date < now && date > aHundredYearsAgo;
}

function dateOfExpiration(dateStr: string): boolean {
  const date = new Date(dateStr);
  const aHundredYearsAgo = new Date(now.getTime() - aHundredYearsInMiliseconds);
  const aHundredFromNow = new Date(now.getTime() + aHundredYearsInMiliseconds);

  return dateStr === '' || (date < aHundredFromNow && date > aHundredYearsAgo);
}

function dateOfRegistration(dateStr: string): boolean {
  const date = new Date(dateStr);
  const aHundredYearsAgo = new Date(now.getTime() - aHundredYearsInMiliseconds);

  return date < now && date > aHundredYearsAgo;
}

const heightRegexp = /\/*[0-2]\.[0-9]{2}$|^$/;
function height(value: string): string | boolean {
  if (!heightRegexp.test(value)) {
    return 'Digite la estatura en el formato apropiado, ej. 1.70';
  }

  return true;
}

const rhRegexp = /^(A|B|AB|O)[+-]$|^$/;
function rh(value: string): string | boolean {
  if (!rhRegexp.test(value)) {
    return 'Digite un tipo de sangre valido';
  }

  return true;
}

export default {
  name,
  namesWithSpecialCharacters,
  place,
  number,
  numberINEData,
  country,
  dateOfBirth,
  dateOfExpedition,
  dateOfExpiration,
  dateOfRegistration,
  height,
  isDate,
  isRequired,
  isYear,
  rh,
};
