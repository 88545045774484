import Vue from 'vue';
import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.use(VueViewer);

Vue.config.productionTip = false;

export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
